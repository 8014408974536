import React, { useRef, useState } from 'react';
import './Tabs.css';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Cascader,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Switch,
  TreeSelect,
  Upload,
  Space,
  Typography,
} from 'antd';
import {confirmDialog} from '../Common/commonui.tsx'


const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Text, Link } = Typography;
const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
}

const FormDisabledDemo: React.FC = () => {
  const [componentDisabled, setComponentDisabled] = useState<boolean>(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [inputState,setInputState] = useState("");
  const { confirm } = Modal;
  const showConfirm = () =>{confirmDialog(inputState,
    ()=>{
      setConfirmLoading(true);
      setTimeout(() => {
        setIsModalOpen1(false);
        setConfirmLoading(false);
      }, 2000);    
    },
    ()=>{
      setIsModalOpen1(false);
    });
  }

  const dialog1 = <Modal title="No Form" open={isModalOpen1} onOk={()=>{}} onCancel={()=>{setIsModalOpen1(false)}} confirmLoading={confirmLoading}
    width={700}
    bodyStyle={{height:500}}
    footer={[
      <Button key="back" onClick={()=>{setIsModalOpen1(false)}}>
        Return
      </Button>,

      <Button key="submit" type="primary" loading={confirmLoading} onClick={
          showConfirm
        }>
        Submit
      </Button>,

      <Button
        key="link"
        href="https://google.com"
        type="primary"
        loading={confirmLoading}
        onClick={
          ()=>{
            setConfirmLoading(true);
            setTimeout(() => {
              setIsModalOpen1(false);
              setConfirmLoading(false);
            }, 2000);
            }
        }
      >
        Search on Google
      </Button>,
    ]}

    >
    <p style={{height:500,overflow:"scroll"}}>
      <Space
        direction="vertical"
        style={{ width: 600 }}
      >   
          <Space
            direction="horizontal"
          >
            <Checkbox
              checked={componentDisabled}
              onChange={(e) => setComponentDisabled(e.target.checked)}
            >
              Form disabled
            </Checkbox>
            <Checkbox>Checkbox</Checkbox>
            <Radio.Group>
              <Radio value="apple"> Apple </Radio>
              <Radio value="pear"> Pear </Radio>
            </Radio.Group>
          </Space>

          <Space
            direction="horizontal"
          >
            <Text>Input:</Text>
            <Input onChange={(e)=>{setInputState(e.currentTarget.value)}}/>
          </Space>

          <Space
            direction="horizontal"
          >
            <Text>Select:</Text>
            <Select style={{ width: 150 }}>
              <Select.Option value="demo">Demo</Select.Option>
            </Select>
            <Text>Tree:</Text>
            <TreeSelect
              style={{ width: 150 }}
              treeData={[
                { title: 'Light', value: 'light', children: [{ title: 'Bamboo', value: 'bamboo' }] },
              ]}
            />
          </Space>

          <Space
            direction="horizontal"
          >
            <Text>Cascader:</Text>
            <Cascader
            style={{ width: 200 }}
            options={[
              {
                value: 'zhejiang',
                label: 'Zhejiang',
                children: [
                  {
                    value: 'hangzhou',
                    label: 'Hangzhou',
                  },
                ],
              },
            ]}
          />
          </Space>

          <DatePicker />
          <RangePicker />
          <Space
            direction="horizontal"
          >
            <Text>InputNumber:</Text>
            <InputNumber />
          </Space>
          <Text>TextArea:</Text>
          <TextArea rows={4} />
          <Space
            direction="horizontal"
          >
            <Text>Switch:</Text>
            <Switch />
          </Space>
          <Upload action="/upload.do" listType="picture-card">
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </Upload>
          <Button  htmlType="submit">Button</Button>
      </Space>
    </p>
  </Modal>

const dialog2 = <Modal title="Form" open={isModalOpen2} onOk={()=>{}} onCancel={()=>{setIsModalOpen2(false)}} confirmLoading={confirmLoading}
width={700}
bodyStyle={{height:500}}
footer={[
  <Button key="back" onClick={()=>{setIsModalOpen2(false)}}>
    Return
  </Button>,

  <Button key="submit" type="primary" loading={confirmLoading} onClick={
      showConfirm
    }>
    Submit
  </Button>,

  <Button
    key="link"
    href="https://google.com"
    type="primary"
    loading={confirmLoading}
    onClick={
      ()=>{
        setConfirmLoading(true);
        setTimeout(() => {
          setIsModalOpen1(false);
          setConfirmLoading(false);
        }, 2000);
        }
    }
  >
    Search on Google
  </Button>,
]}

>
<p style={{height:500,overflow:"scroll"}}>
<Checkbox
    checked={componentDisabled}
    onChange={(e) => setComponentDisabled(e.target.checked)}
  >
    Form disabled
  </Checkbox>
  <Form
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 14 }}
    layout="horizontal"
    disabled={componentDisabled}
    style={{ width: 600 }}
  >
    <Form.Item label="Checkbox" name="disabled" valuePropName="checked">
      <Checkbox>Checkbox</Checkbox>
    </Form.Item>
    <Form.Item label="Radio">
      <Radio.Group>
        <Radio value="apple"> Apple </Radio>
        <Radio value="pear"> Pear </Radio>
      </Radio.Group>
    </Form.Item>
    <Form.Item label="Input"
      rules={[{ required: true, message: 'Please input something!' }]}
    >
      <Input />
    </Form.Item>
    <Form.Item label="Select">
      <Select>
        <Select.Option value="demo">Demo</Select.Option>
      </Select>
    </Form.Item>
    <Form.Item label="TreeSelect">
      <TreeSelect
        treeData={[
          { title: 'Light', value: 'light', children: [{ title: 'Bamboo', value: 'bamboo' }] },
        ]}
      />
    </Form.Item>
    <Form.Item label="Cascader">
      <Cascader
        options={[
          {
            value: 'zhejiang',
            label: 'Zhejiang',
            children: [
              {
                value: 'hangzhou',
                label: 'Hangzhou',
              },
            ],
          },
        ]}
      />
    </Form.Item>
    <Form.Item label="DatePicker">
      <DatePicker />
    </Form.Item>
    <Form.Item label="RangePicker">
      <RangePicker />
    </Form.Item>
    <Form.Item label="InputNumber">
      <InputNumber />
    </Form.Item>
    <Form.Item label="TextArea">
      <TextArea rows={4} />
    </Form.Item>
    <Form.Item label="Switch" valuePropName="checked">
      <Switch />
    </Form.Item>
    <Form.Item label="Upload" valuePropName="fileList" getValueFromEvent={normFile}>
      <Upload action="/upload.do" listType="picture-card">
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      </Upload>
    </Form.Item>
    <Form.Item label="Button">
      <Button  htmlType="submit">Button</Button>
    </Form.Item>
  </Form>
</p>
</Modal>

  return (
    <div className='commonContent'>
      <Button type="primary" onClick={()=>{setIsModalOpen1(true)}}>
        Open Modal
      </Button>
      <Button type="primary" onClick={()=>{setIsModalOpen2(true)}}>
        Open Modal2
      </Button>
      {dialog1}
      {dialog2}
    </div>
  );
};

export default () => <FormDisabledDemo />;