import React, { useRef, useState,useEffect } from 'react';
import dayjs from 'dayjs';
import {
  Col, Row, 
  Button,
  Tooltip,
  Space,
  Table,
  Tag,
  Cascader,
  Checkbox,
  Divider,
  DatePicker,
  TimePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Switch,
  TreeSelect,
  Upload,
  Typography,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import {confirmDialog,oneButtonConfirmDialog} from '../Common/commonui.tsx'
import {emptyCache,CallAPI,GetData,ConsoleLog,TolocalDateString,} from '../Common/commonfunction.tsx';
import './Tabs.css';

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Text, Link } = Typography;
const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
}

interface DataType {
  key: string;
  VehicleID: string;
  License2: string;
  CarType: string;
  CarMake: string;
  CarModel: string;
  CarFirstRegDatetime: string;
  CarColor: string;
  Remarks:string;
  Status:string;
  CreatedBy:string;
  CreatedDatetime:string;
  UpdatedBy:string;
  UpdatedDatetime:string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Vehicle ID',
    dataIndex: 'VehicleID',
    key: 'VehicleID',
    render: (_:any,record:DataType) => <Tooltip placement="rightTop" title={
      <>
        <div>{record.VehicleID}</div>
        <div>{record.License2}</div>
        <div>{record.CarType}</div>
        <div>{record.CarMake}</div>
        <div>{record.CarModel}</div>
        <div>{record.CarFirstRegDatetime}</div>
        <div>{record.CarColor}</div>
        <div>{record.Remarks}</div>
        <div>{record.Status}</div>
        <div>{record.CreatedBy}</div>
        <div>{record.CreatedDatetime}</div>
        <div>{record.UpdatedBy}</div>
        <div>{record.UpdatedDatetime}</div>
      </>
    }><Button>{record.VehicleID}</Button></Tooltip>,
    sorter: (a, b) =>  a.VehicleID.localeCompare(b.VehicleID),
    //defaultSortOrder: 'ascend',
  },
  {
    title: 'License',
    dataIndex: 'License2',
    key: 'License2',
    sorter: (a, b) => a.License2.localeCompare(b.License2),
  },
  {
    title: 'CarType',
    dataIndex: 'CarType',
    key: 'CarType',
    sorter: (a, b) => a.CarType.localeCompare(b.CarType),
  },
  {
    title: 'CarMake',
    key: 'CarMake',
    dataIndex: 'CarMake',
    sorter: (a, b) => a.CarMake.localeCompare(b.CarMake),
  },
  {
    title: 'CarModel',
    key: 'CarModel',
    dataIndex: 'CarModel',
    sorter: (a, b) => a.CarModel.localeCompare(b.CarModel),
  },
  {
    title: 'CarColor',
    key: 'CarColor',
    dataIndex: 'CarColor',
    sorter: (a, b) => a.CarColor.localeCompare(b.CarColor),
  },
  {
    title: 'Remarks',
    key: 'Remarks',
    dataIndex: 'Remarks',
    sorter: (a, b) => a.Remarks.localeCompare(b.Remarks),
  },
  {
    title: 'Status',
    key: 'Status',
    dataIndex: 'Status',
    sorter: (a, b) => a.Status.localeCompare(b.Status),
  },
];

//#1677ff
//none
//topLeft
//bottomRight
function App() {
  const textwidth = 200;
  const [loading,SetLoading] = useState(true);
  const [isModalOpen1,SetIsModalOpen1] = useState(false);
  const [confirmLoading,SetConfirmLoading] = useState(false);
  const [IsEdit,setIsEdit] = useState(false);

  const [inputState,SetInputState] = useState("");
  const [dialogTitle,SetDialogTitle] = useState("Vehicle");
  const [VehicleList,SetVehicleList] = useState([]);
  
  const [VehicleID,SetVehicleID] = useState("")
  const [License2,SetLicense2] = useState("")
  const [CarType,SetCarType] = useState("")
  const [CarMake,SetCarMake] = useState("")
  const [CarModel,SetCarModel] = useState("")
  const [CarFirstRegDatetime,SetCarFirstRegDatetime] = useState("")
  const [CarColor,SetCarColor] = useState("")
  const [Remarks,SetRemarks] = useState("")
  const [Status,SetStatus] = useState("")
  const [CreatedBy,SetCreatedBy] = useState("")
  const [CreatedDatetime,SetCreatedDatetime] = useState("")
  const [UpdatedBy,SetUpdatedBy] = useState("")
  const [UpdatedDatetime,SetUpdatedDatetime] = useState("")

/*
{
    "VehicleID": "ABC123",
    "License2": "",
    "CarType": "",
    "CarMake": "",
    "CarModel": "",
    "CarFirstRegDatetime": "2023-07-24 16:21:15",
    "CarColor": "",
    "Remarks": "",
    "Status": 1,
    "CreatedBy": "test",
    "CreatedDatetime": "2023-07-24 16:21:21",
    "UpdatedBy": "test",
    "UpdatedDatetime": "2023-07-24 16:21:23"
}
*/
  const GetVehicle = () =>{
    var para0 = "";
    var para1 = "action=GetVehicle";
    var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
    var para3 = para1 + para2;
    //ConsoleLog(para3)
    GetData(para3,
        data => {
          //ConsoleLog(data)
        if(data["result"] === "200"){
          var list = JSON.parse(data["itemObject"])
          SetVehicleList(list)
        }else{
          oneButtonConfirmDialog("Error","Please Login Again",()=>{})
        }
        SetLoading(false)
        },
        ()=>{oneButtonConfirmDialog("Network Error","",()=>{});SetLoading(false)}
    )
}

const CreateVehicle = () =>{
  if(VehicleID === "" || VehicleID === " " ||VehicleID === null){
    oneButtonConfirmDialog("Please input VehicleID","",()=>{})
    return
  }
  var para0 = JSON.stringify(
    {
      VehicleID:VehicleID,
      License2:License2,
      CarType:CarType,
      CarMake:CarMake,
      CarModel:CarModel,
      CarFirstRegDatetime:CarFirstRegDatetime,
      CarColor:CarColor,
      Remarks:Remarks,
      Status:Status
  }
  )
  var para1 = "action=CreateVehicle";
  var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
  var para3 = para1 + para2 + "&itemObject=" + para0;;
  //ConsoleLog(para3)
  GetData(para3,
      data => {
        ConsoleLog(data)
      if(data["result"] === "200"){
        GetVehicle()
        SetIsModalOpen1(false)
      }else{
        oneButtonConfirmDialog("Error","Please Login Again",()=>{})
      }
      SetLoading(false)
      },
      ()=>{oneButtonConfirmDialog("Network Error","",()=>{});SetLoading(false)}
  )
}

const UpdateVehicle = () =>{
  if(VehicleID === "" || VehicleID === " " ||VehicleID === null){
    oneButtonConfirmDialog("Please input VehicleID","",()=>{})
    return
  }
  var para0 = JSON.stringify(
    {
      VehicleID:VehicleID,
      License2:License2,
      CarType:CarType,
      CarMake:CarMake,
      CarModel:CarModel,
      CarFirstRegDatetime:CarFirstRegDatetime,
      CarColor:CarColor,
      Remarks:Remarks,
      Status:Status
  }
  )
  var para1 = "action=UpdateVehicle";
  var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
  var para3 = para1 + para2 + "&itemObject=" + para0;;
  //ConsoleLog(para3)
  GetData(para3,
      data => {
        ConsoleLog(data)
      if(data["result"] === "200"){
        GetVehicle()
        SetIsModalOpen1(false)
      }else{
        oneButtonConfirmDialog("Error","Please Login Again",()=>{})
      }
      SetLoading(false)
      },
      ()=>{oneButtonConfirmDialog("Network Error","",()=>{});SetLoading(false)}
  )
}

useEffect(
  ()=>{
      const timer = setTimeout(()=>{
        GetVehicle()
      },500)
      return () => clearTimeout(timer)
  }
,[]);
//<p style={{height:500,overflow:"scroll"}}>
const dialog1 = <Modal title={dialogTitle} open={isModalOpen1} onOk={()=>{}} onCancel={()=>{SetIsModalOpen1(false)}} confirmLoading={confirmLoading}
width={500}
bodyStyle={{height:450}}
footer={[
  <Button key="back" onClick={()=>{SetIsModalOpen1(false)}}>
    Return
  </Button>,

  <Button key="submit" type="primary" loading={confirmLoading} onClick={
      ()=>{
        if(IsEdit){UpdateVehicle()}else{CreateVehicle()}
      }
    }>
    Submit
  </Button>,
]}

>
  <div>
  {/*<p style={{height:500,overflow:"scroll"}}> */}
  <Space
    direction="vertical"
    style={{ width: 450,marginTop:20,marginLeft:50,marginRight:50 }}
  >   
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>*License Plate1:</Text></Col>
        <Col span={16}><Input  value={VehicleID} onChange={(e)=>{SetVehicleID(e.target.value)}} style={{ width: textwidth }} disabled={IsEdit}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>License Plate2:</Text></Col>
        <Col span={16}><Input value={License2} onChange={(e)=>{SetLicense2(e.target.value)}} style={{ width: textwidth }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Car Type:</Text></Col>
        <Col span={16}>
          <Select value={CarType} onChange={(e)=>{SetCarType(e)}} style={{ width: textwidth }}>
            <Select.Option value="A">A</Select.Option>
            <Select.Option value="B">B</Select.Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Car Make:</Text></Col>
        <Col span={16}>
          <Select value={CarMake} onChange={(e)=>{SetCarMake(e)}} style={{ width: textwidth }}>
            <Select.Option value="Toyota">Toyota</Select.Option>
            <Select.Option value="Mercedes-Benz">Mercedes-Benz</Select.Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Car Modal:</Text></Col>
        <Col span={16}>
          <Select value={CarModel} onChange={(e)=>{SetCarModel(e)}} style={{ width: textwidth }}>
            <Select.Option value="Benz S">Benz S</Select.Option>
            <Select.Option value="Benz EQE">Benz EQE</Select.Option>
            <Select.Option value="MPV">MPV</Select.Option>
          </Select>
        </Col>
      </Row>

      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>CarFirstRegDatetime:</Text></Col>
        <Col span={16}><DatePicker format="YYYY-MM-DD HH:mm:ss" value={dayjs(CarFirstRegDatetime,"YYYY-MM-DD HH:mm:ss")} showTime={true} onChange={(e)=>{if(e !== null)SetCarFirstRegDatetime(TolocalDateString(e?.toISOString()))}}/></Col>
      </Row>

      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>CarColor:</Text></Col>
        <Col span={16}><Input value={CarColor} onChange={(e)=>{SetCarColor(e.target.value)}} style={{ width: textwidth }}/></Col>
      </Row>
      
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Status:</Text></Col>
        <Col span={16}>
          <Select value={Status} onChange={(e)=>{SetStatus(e)}} style={{ width: textwidth }}>
            <Select.Option value="a">Active</Select.Option>
            <Select.Option value="b">Inactive</Select.Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Remarks:</Text></Col>
        <Col span={16}><TextArea value={Remarks} onChange={(e)=>{SetRemarks(e.target.value)}} style={{ width: textwidth, resize: 'none' }}rows={4} /></Col>
      </Row>

      <Space
        direction="horizontal"
      >

      </Space>
      
  </Space>
{/*</p>*/}
</div>
</Modal>

  return (
    <div className='commonContent' style={{background:'#ffffff',height:765,minWidth:1440,padding:'0vh',overflow:"hidden"}}>
      <Table style={{minWidth:1440,height:725,padding:'0vh'}} scroll={{ x: '90vw', y: 700 }} columns={columns} dataSource={VehicleList} pagination={{ position: ['none','none'],pageSize: 1000 }}
      onRow={(r) => ({
        onClick: () => {
          SetDialogTitle('Edit Vehicle');
          setIsEdit(true)
          SetIsModalOpen1(true)
          SetVehicleID(r.VehicleID)
          SetLicense2(r.License2)
          SetCarType(r.CarType)
          SetCarMake(r.CarMake)
          SetCarModel(r.CarModel)
          SetCarFirstRegDatetime(r.CarFirstRegDatetime)
          SetCarColor(r.CarColor)
          SetRemarks(r.Remarks)
          SetStatus(r.Status)
        },
      })}
      />
      <div style={{paddingLeft:'1vw',paddingRight:'1vw'}}> 
        <Button type="primary" 
          style={{width:100,position: 'relative',float: 'right',paddingRight:'1vw'}}
          onClick={()=>{
            SetDialogTitle('Create Vehicle')
            setIsEdit(false)
            SetIsModalOpen1(true)
            SetVehicleID("")
            SetLicense2("")
            SetCarType("")
            SetCarMake("")
            SetCarModel("")
            SetCarFirstRegDatetime("")
            SetCarColor("")
            SetRemarks("")
            SetStatus("")
            }}>
          Add
        </Button>
      </div>
      {dialog1}
    </div>
  );
}

export default App;
