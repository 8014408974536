import React, { useRef, useState,useEffect } from 'react';
import {
  AutoComplete,
  Col, Row, 
  Button,
  Tooltip,
  Space,
  Table,
  Tag,
  Cascader,
  Checkbox,
  Divider,
  DatePicker,
  TimePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Switch,
  TreeSelect,
  Upload,
  Typography,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import {confirmDialog,oneButtonConfirmDialog} from '../Common/commonui.tsx'
import {emptyCache,CallAPI,GetData,ConsoleLog,TolocalDateString,} from '../Common/commonfunction.tsx';
import './Tabs.css';

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Text, Link } = Typography;
const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
}

interface DataType {
  key: string;
  DriverID: string;
  Name: string;
  Password: string;
  DriverTelNo: string;
  Status: string;
  DriverEmail: string;
  ServiceProvider: string;
  Vendor: string;
  Remarks: string;
  CreatedBy: string;
  CreatedDatetime: string;
  UpdatedBy: string;
  UpdatedDatetime: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: '',
    dataIndex: 'DriverID',
    key: 'DriverID',
    render: (_:any,record:DataType) => <div></div>,
    width:"1%"
  },
  {
    title: 'Driver ID',
    dataIndex: 'DriverID',
    key: 'DriverID',
    render: (_:any,record:DataType) => <Tooltip placement="rightTop" title={
      <>
        <div>{record.DriverID}</div>
        <div>{record.Name}</div>
        <div>{record.DriverTelNo}</div>
        <div>{record.DriverEmail}</div>
        <div>{record.ServiceProvider}</div>
        <div>{record.Vendor}</div>
        <div>{record.Remarks}</div>
        <div>{record.Status}</div>
      </>
    }><Button>{record.DriverID}</Button></Tooltip>,
    sorter: (a, b) =>  a.DriverID.localeCompare(b.DriverID),
    //defaultSortOrder: 'ascend',
  },
  {
    title: 'Driver Name',
    dataIndex: 'Name',
    key: 'Name',
    sorter: (a, b) => a.Name.localeCompare(b.Name),
  },
  {
    title: 'Phone',
    dataIndex: 'DriverTelNo',
    key: 'DriverTelNo',
    sorter: (a, b) => a.DriverTelNo.localeCompare(b.DriverTelNo),
  },
  {
    title: 'Email',
    key: 'DriverEmail',
    dataIndex: 'DriverEmail',
    sorter: (a, b) => a.DriverEmail.localeCompare(b.DriverEmail),
    width:"20%"
  },
  {
    title: 'ServiceProvider',
    key: 'ServiceProvider',
    dataIndex: 'ServiceProvider',
    sorter: (a, b) => a.ServiceProvider.localeCompare(b.ServiceProvider),
  },
  {
    title: 'Vendor',
    key: 'Vendor',
    dataIndex: 'Vendor',
    sorter: (a, b) => a.Vendor.localeCompare(b.Vendor),
  },
  
  {
    title: 'Remarks',
    key: 'Remarks',
    dataIndex: 'Remarks',
    sorter: (a, b) => a.Remarks.localeCompare(b.Remarks),
  },
  {
    title: 'Status',
    key: 'Status',
    dataIndex: 'Status',
    sorter: (a, b) => a.Status.localeCompare(b.Status),
    render: (_:any,record:DataType) => <Text>{record.Status.toString()==="1"?"Active":"Inactive"}</Text>
  },
];

//#1677ff
//none
//topLeft
//bottomRight
interface SelectOption {
  label: string;
  value: string;
}
function App() {
  const textwidth = 200;
  const [loading,SetLoading] = useState(true);
  const [isModalOpen1,SetIsModalOpen1] = useState(false);
  const [confirmLoading,SetConfirmLoading] = useState(false);

  const [inputState,SetInputState] = useState("");
  const [dialogTitle,SetDialogTitle] = useState("Driver");
  const [DriverList,SetDriverList] = useState([]);

  const [DriverID,SetDriverID] = useState("")
  const [Name,SetName] = useState("")
  const [Password,SetPassword] = useState("")
  const [DriverTelNo,SetDriverTelNo] = useState("")
  const [Status,SetStatus] = useState("")
  const [UUID,SetUUID] = useState("")
  const [Remarks,SetRemarks] = useState("")
  const [DriverEmail,SetDriverEmail] = useState("")
  const [DriverEmail1,SetDriverEmail1] = useState("")
  const [DriverEmail2,SetDriverEmail2] = useState("")
  const [ServiceProvider,SetServiceProvider] = useState("")
  const [Vendor,SetVendor] = useState("")
  const [CreatedBy,SetCreatedBy] = useState("")
  const [CreatedDatetime,SetCreatedDatetime] = useState("")
  const [UpdatedBy,SetUpdatedBy] = useState("")
  const [UpdatedDatetime,SetUpdatedDatetime] = useState("")
  const [IsErrorDialog,SetIsErrorDialog] = useState(false);
  const [ServiceVenderListfiltered,SetServiceVenderListfiltered] = useState<SelectOption[]>([])

  const GetVendor = () =>{
    var para0 = "";
    var para1 = "action=GetVendor";
    var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
    var para3 = para1 + para2;
    GetData(para3,
        data => {
        if(data["result"] === "200"){
          var list = JSON.parse(data["itemObject"])
          var selectItemList:SelectOption[] = []
          for(var k = 0;k<list.length;k++){
            selectItemList.push({label: list[k]['VendorName'].toString(), value: list[k]['VendorName'].toString()})
          }
          SetServiceVenderListfiltered(selectItemList)
        }else{
            if(!IsErrorDialog){
                SetIsErrorDialog(true)
                oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false)})
            }
        }
        SetLoading(false)
        },
        ()=>{
            if(!IsErrorDialog){
                SetIsErrorDialog(true)
                oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false)})
            }
            SetLoading(false);
        }
    )
}
/*
{
    "DriverID": "ben",
    "Name": "Ben Wong",
    "Password": "123",
    "DriverTelNo": "",
    DriverEmail
    "Status": 1,
    "Remarks": "",
    UUID
    ServiceProvider
    Vendor
    "CreatedBy": "test",
    "CreatedDatetime": "2023-07-11 17:32:46",
    "UpdatedBy": "test",
    "UpdatedDatetime": "2023-07-11 17:32:49",
    "key": 0
}
*/
  const GetDriver = () =>{
    var para0 = "";
    var para1 = "action=GetDriver";
    var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
    var para3 = para1 + para2;
    ConsoleLog(para3)
    GetData(para3,
        data => {
          ConsoleLog(data)
        if(data["result"] === "200"){
          var list = JSON.parse(data["itemObject"])
          SetDriverList(list)
        }else{
          oneButtonConfirmDialog("Error","Please Login Again",()=>{})
        }
        SetLoading(false)
        },
        ()=>{oneButtonConfirmDialog("Network Error","",()=>{});SetLoading(false)}
    )
}

const CreateDriver = () =>{
  var para0 = JSON.stringify(
    {
      DriverID:DriverID,
      Name:Name,
      Password:Password,
      DriverTelNo:DriverTelNo,
      DriverEmail:DriverEmail1+"@"+DriverEmail2,
      UUID:UUID,
      Remarks:Remarks,
      ServiceProvider:ServiceProvider,
      Vendor:Vendor,
      Status:Status
    }   
  )
  var para1 = "action=CreateDriver";
  if(DriverID !== ""){
    para1 = "action=UpdateDriver";
  }
  var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
  var para3 = para1 + para2 + "&itemObject=" + para0;;
  GetData(para3,
      data => {
        ConsoleLog(data)
      if(data["result"] === "200"){
        GetDriver()
        oneButtonConfirmDialog("Driver Record Saved","",()=>{SetIsModalOpen1(false)})
      }else{
        oneButtonConfirmDialog(`Error ${data["result"]}`,"Please Login Again",()=>{})
      }
      SetLoading(false)
      },
      ()=>{oneButtonConfirmDialog("Network Error","",()=>{});SetLoading(false)}
  )
}

useEffect(
  ()=>{
      const timer = setTimeout(()=>{
        GetVendor()
        GetDriver()
      },500)
      return () => clearTimeout(timer)
  }
,[]);

const dialog1 = <Modal title={dialogTitle} open={isModalOpen1} onOk={()=>{}} onCancel={()=>{SetIsModalOpen1(false)}} confirmLoading={confirmLoading}
width={500}
bodyStyle={{height:450}}
footer={[
  <Button key="back" onClick={()=>{SetIsModalOpen1(false)}}>
    Return
  </Button>,

  <Button key="submit" type="primary" loading={confirmLoading} onClick={
      ()=>{
        if(DriverTelNo !== "" && Password !== ""){
          confirmDialog(DriverID === ""?"Create Driver?":"Edit Driver?","",()=>{CreateDriver()},()=>{})
        }else{
          oneButtonConfirmDialog("Phone and Password must not be blank","",()=>{})
        }
      }
    }>
    Save
  </Button>,
]}

>
  <div>
  <Space
    direction="vertical"
    style={{ width: 400,marginTop:20,marginLeft:50,marginRight:50 }}
  >   
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Driver ID:</Text></Col>
        <Col span={16}><Input disabled={true} value={DriverID} onChange={(e)=>{SetDriverID(e.target.value)}} style={{ width: "99%" }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Password:</Text></Col>
        <Col span={16}><Input disabled={false} value={Password} onChange={(e)=>{SetPassword(e.target.value)}} style={{ width: "99%" }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Driver Name:</Text></Col>
        <Col span={16}><Input disabled={ServiceProvider==="DCH"} value={Name} onChange={(e)=>{SetName(e.target.value)}} style={{ width: "99%" }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Phone:</Text></Col>
        <Col span={16}><InputNumber disabled={ServiceProvider==="DCH"} value={DriverTelNo} onChange={(e)=>{SetDriverTelNo(e!)}} controls={false} style={{ width: "99%" }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Email:</Text></Col>
        <Col span={6}><Input disabled={ServiceProvider==="DCH"} value={DriverEmail1} onChange={(e)=>{SetDriverEmail1(e.target.value)}} style={{ width: "99%" }}/></Col>
        <Col span={1} style={{ paddingTop:5 }}><Text>@</Text></Col>
        <Col span={9}><Input disabled={ServiceProvider==="DCH"} value={DriverEmail2} onChange={(e)=>{SetDriverEmail2(e.target.value)}} style={{ width: "99%" }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>ServiceProvider:</Text></Col>
        <Col span={16}><Input disabled={true} value={ServiceProvider} onChange={(e)=>{SetServiceProvider(e.target.value)}} style={{ width: "99%" }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Vendor:</Text></Col>
        <Col span={16}>
        <AutoComplete disabled={ServiceProvider==="DCH"} 
                                value={Vendor}
                                options={
                                    ServiceVenderListfiltered
                                }
                                style={{ width: "99%",marginTop:5 }}
                                onSelect={(e)=>{                                   
                                    SetVendor(e)
                                }}
                                onChange={(e)=>{
                                    SetVendor(e)
                                }}
                                filterOption={(inputValue, option) =>
                                    option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                placeholder=""/>
        </Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Status:</Text></Col>
        <Col span={16}>
          <Select disabled={ServiceProvider==="DCH"} value={Status.toString()==="1"?"Active":"Inactive"} onChange={(e)=>{SetStatus(e.toString())}} style={{ width: "99%" }}>
            <Select.Option value="1">Active</Select.Option>
            <Select.Option value="0">Inactive</Select.Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Remarks:</Text></Col>
        <Col span={16}><TextArea disabled={ServiceProvider==="DCH"} value={Remarks} onChange={(e)=>{SetRemarks(e.target.value)}} style={{ width: "99%" }}rows={4} /></Col>
      </Row>

      <Space
        direction="horizontal"
      >

      </Space>
      
  </Space>
{/*</p>*/}
</div>
</Modal>

  return (
    <div className='commonContent' style={{background:'#ffffff',height:800,minWidth:1440,padding:'0vh',overflow:"hidden"}}>
      <Table style={{minWidth:1440,height:765,padding:'0vh'}} scroll={{ x: '90vw', y: 720 }} columns={columns} dataSource={DriverList} pagination={{ position: ['none','none'],pageSize: 1000 }}
      onRow={(r) => ({
        onDoubleClick: () => {
          SetIsModalOpen1(true)
          SetDriverID(r.DriverID)
          SetName(r.Name)
          SetPassword(r.Password)
          SetDriverTelNo(r.DriverTelNo)
          SetDriverEmail(r.DriverEmail)
          const emailsplit = r.DriverEmail.split("@")
          if(emailsplit.length>1){
            SetDriverEmail1(emailsplit[0])
            SetDriverEmail2(emailsplit[1])
          }else{
            SetDriverEmail1("")
            SetDriverEmail2("")
          }
          SetServiceProvider(r.ServiceProvider)
          SetVendor(r.Vendor)
          SetRemarks(r.Remarks)
          SetStatus(r.Status)
        },
      })}
      />
      <div style={{paddingLeft:'1vw',paddingRight:'1vw'}}> 
        <Button type="primary" 
          style={{width:100,position: 'relative',float: 'right',paddingRight:'1vw'}}
          onClick={()=>{
            SetDriverID("")
            SetName("")
            SetPassword("")
            SetDriverTelNo("")
            SetDriverEmail("")
            SetServiceProvider("Consignment")
            SetVendor("")
            SetRemarks("")
            SetStatus("7")
            SetIsModalOpen1(true)
            }}>
          Add
        </Button>
      </div>
      {dialog1}
    </div>
  );
}

export default App;
