// export const webUrl = "http://www.jdts.biz:5810/#/"
// export const Server = "http://www.jdts.biz:5809/"//2041
// export const Server2 = "WebAppAPI/API/MainHandler.ashx"

// export const webUrl = "https://uat-limo.dchml.com.hk/"
// export const Server = "https://uat-limo.dchml.com.hk/api/"
// export const Server2 = "WebAppAPI/API/MainHandler.ashx"

export const webUrl = "https://limo.dchml.com.hk/"
export const Server = "https://limo.dchml.com.hk/api/"
export const Server2 = "WebAppAPI/API/MainHandler.ashx"

// export const webUrl = "http://localhost:3000/"
// export const Server = "http://www.jdts.biz:5809/"//2041
// export const Server2 = "WebAppAPI/API/MainHandler.ashx"

export const versionNumber = "1.0"

export const headerHeight = 70;
export const tabHeight = 37;
export const paddingValue = 5;

export const NumericOnly= (e) => { //angka only
    const reg = /^[0-9\b]+$/
    let preval=e.target.value
    if (e.target.value === '' || reg.test(e.target.value)) return true
    else e.target.value = preval.substring(0,(preval.length-1))
} 

export const AlphabetOnly= (e) => { //angka only 
    //正規表示式
    const reg = /^[A-Za-z\b]+$/
    let preval=e.target.value
    if (e.target.value === '' || reg.test(e.target.value)) return true
    else e.target.value = preval.substring(0,(preval.length-1))
}

export function HandleLogoutClick(){
    //window.location.replace(Server)
    //window.location.replace("http://www.jdts.biz:8085/")
    //window.location.replace("http://192.168.8.15:8083/")
    //window.location.replace(Server3)
  }
  export function HandleLogoutClick2(){
        var para1 = "action=GetImportInfo";
        var para2 = para1 + "&token=" + localStorage.getItem('DCHtoken');
        GetData(para2,
            data => {
                HandleLogoutClick()
            },
            ()=>{}
          );
    //window.location.replace(Server)
    //window.location.replace("http://www.jdts.biz:8085/")
    //window.location.replace("http://192.168.8.15:8083/")
    //window.location.replace("http://127.0.0.1:8083/")
  }

//export let logoutButton = <Button style={{marginLeft:3,marginRight:3}} variant="contained" color="primary" onClick={HandleLogoutClick} >Logout</Button>

export function download(data, filename, type) {
    // Decode Base64 string
    const decodedData = window.atob(data);

    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i);
    }
    var file = new Blob([uInt8Array], {type: type});
    if ((window.navigator as any).msSaveOrOpenBlob) // IE10+
        (window.navigator as any).msSaveOrOpenBlob(file, filename);
    else { // Others
        var a = document.createElement("a"),
                url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);  
        }, 0); 
    }
}


export function ConsoleLog(contentString){
    console.log(contentString);
}
//<div style={{height:50, background:"#3F51B5", width: '100%' }}>
// export function Topbar(){
//     return(
//         <div style={{height:50, background:"#3F51B5", width: '100%' }}>
//         <img style={{height:50}} src="Image/titlelogo.png" alt=""/>
//         </div>
//     )
// }
// export function Topbar2(){
//     return(
//         <div style={{height:50, background:"#3F51B5", width: '100%' }}>
//         <img style={{height:50}} src="Image/titlelogo.png" alt=""/>
//         {LogoutButton}
//         </div>
//     )
// }

export const CallAPI = (para,callback) => {
    //Sample:Token, Type(Worker), PhotoID(staffcode.jpg)
    //setIsLoaded(false)
    fetch(Server + Server2,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(para)
    })
      .then(
        res => {
          ConsoleLog(res)
          const jsonList = res.json();
          if(res.status === 200){
            ConsoleLog(jsonList)
            ConsoleLog("200")
            jsonList.then(
              (result) => {
                if(result.length > 0){
                    callback(result)
                }
              }
            )
          }          
          if(res.status === 400){
            //ConsoleLog("400 error");
            jsonList.then(
              (result) => {
                if(result.length > 0){
                  //setErrorNo(result[0]["Error"]);
                  //setError(true);
                }
              }
            )
          }
          return jsonList;
        }
      )
  }

export function GetData(para,callback,timeoutFn) {
    // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest()

    // get a callback when the server responds
    xhr.addEventListener('load', () => {
      // update the state of the component with the result here
      //console.log(xhr.responseText)
    })
    xhr.onreadystatechange = function() { // Call a function when the state changes.
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            // Request finished. Do processing here.
            //ConsoleLog(xhr.response);
            const jsonList = JSON.parse(xhr.response);
            //ConsoleLog(jsonList)
            callback(jsonList);
        }
    }
    xhr.ontimeout = (e) => {
      timeoutFn();
    }
    // open the request with the verb and the url
    xhr.open('POST', Server+Server2)
    xhr.timeout = 30000
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    // send the request
    xhr.send(para)
  }

  export const downloadBulkUploadTemplete = async (downloadLink,fileName) => {
    fetch(downloadLink, {
        method: 'GET',
    }).then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();  //afterwards we remove the element again 
        });
    }

  export function TolocalDateString(DateString){//utc to local yyyy-mm-dd HH:MM:SS.000Z
    var DateObject = new Date(DateString.replace("Z","")+"Z");
    const offset = DateObject.getTimezoneOffset()
    DateObject = new Date(DateObject.getTime() - (offset*60*1000))
    return DateObject.toISOString();
  }

  export function DistanceByLatLng(lat1,lon1,lat2,lon2){
    const R = 6371000; // metres
    const φ1 = lat1 * Math.PI/180; // φ, λ in radians
    const φ2 = lat2 * Math.PI/180;
    const Δφ = (lat2-lat1) * Math.PI/180;
    const Δλ = (lon2-lon1) * Math.PI/180;
    
    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ/2) * Math.sin(Δλ/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    
    const d = R * c; // in metres
    return d
  }

    // Function to clear complete cache data
    export const clearCacheData = () => {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        //alert('Complete Cache Cleared')
      };

    export function emptyCache(){
        if('caches' in window){
        caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                })
            });
    
            // Makes sure the page reloads. Changes are only visible after you refresh.
            //window.location.reload(true);
        }
    }