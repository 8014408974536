import React from 'react';
import { Tabs } from 'antd';
import './Tabs.css';
import Tab1 from './Tabs1.tsx';
import Tab2 from './Tabs2.tsx';
import Tab3 from './Tabs3.tsx';
import Tab4 from './Tabs4.tsx';
import Tab5 from './Tabs5.tsx';

const onChange = (key) => {
  console.log(key);
};

const items= [
  {
    key: '1',
    label: `Tab 1 ssssssssss`,
    children: <Tab1/>,
  },
  {
    key: '2',
    label: `Tab 2 aaaaaaaaaa`,
    children: <Tab2/>,
  },
  {
    key: '3',
    label: `Tab 3 bbbbbbbbbb`,
    children: <Tab3/>,
  },
  {
    key: '4',
    label: `Tab 4 zzzzzzzzz`,
    children: <Tab4/>,
  },
  {
    key: '5',
    label: `Tab 5 zzzzzzzzz`,
    children: <Tab5/>,
  },
];
//tabBarStyle={{background:'#4F61C5'}} type='card'
//tabBarStyle={{background:'#1677ff',padding:'1vh'}}<-- can try try
function App() {
  return (
    <div className='content' style={{padding:'0vh',overflow:'hidden'}}>
      <div style={{background:'#0566cc',color:'#ffffff',height:'8vh',padding:'1vh'}}>
        <div style={{position: 'absolute',width:'100vw'}}>
          <h2>Airport Limousine Services Limited</h2>
        </div>
        <div style={{position: 'relative',float: 'right',width:'100vw',textAlign:"right"}}>
          User: Administrator
        </div>
        <div style={{position: 'relative',float: 'right',width:'100vw',textAlign:"right"}}>
          Order | Map | Messenger | Report | User | Logout
        </div>
      </div>
      <div style={{background:'#1677ff',padding:'0px'}}>
        <Tabs tabBarStyle={{background:'#1677ff',padding:'1vh'}} size='large' defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </div>
  );
}

export default App;
