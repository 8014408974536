import React from 'react';
import { Tabs } from 'antd';
import { CarOutlined, ContainerOutlined,MailOutlined } from '@ant-design/icons';
import './Tabs.css';
import Job from './Job.tsx';
import Message from './Message.tsx';
import OrderList from './OrderList.tsx';

const onChange = (key) => {
  console.log(key);
};

const items= [
  {
    key: '1',
    label: (
        <span>
          <CarOutlined />
          Job
        </span>
      ),
    children: <Job/>,
  },
  {
    key: '2',
    label: (
        <span>
          <ContainerOutlined />
          Order
        </span>
      ),
    children: <OrderList/>,
  },
  {
    key: '3',
    label: (
        <span>
          <MailOutlined />
          Message
        </span>
      ),
    children: <Message/>,
  },
];
//tabBarStyle={{background:'#4F61C5'}} type='card'
//tabBarStyle={{background:'#1677ff',padding:'1vh'}}<-- can try try
function App() {
  return (
    <div className='content' style={{width:432,height:768,padding:'0vh',overflow:'hidden'}}>
      <div style={{background:'#1677ff',height:'100vh',paddingTop:'1vh',paddingLeft:'1vh',paddingRight:'1vh',paddingBottom:'1vh'}}>
        <Tabs tabBarStyle={{background:'#1677ff',height:'8vh',padding:'0vh'}} size='large' defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </div>
  );
}

export default App;
