import React, { useRef, useState,useEffect, useMemo } from 'react';
import { 
  Col, Row,
  Button,
  Tooltip,
  Space,
  Table,
  Tag,
  Cascader,
  Checkbox,
  Divider,
  DatePicker,
  TimePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Switch,
  TreeSelect,
  Upload,
  Typography,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import {confirmDialog,oneButtonConfirmDialog} from '../Common/commonui.tsx'
import {emptyCache,CallAPI,GetData,ConsoleLog,TolocalDateString,} from '../Common/commonfunction.tsx';
import './Tabs.css';

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Text, Link } = Typography;
const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
}
interface SelectOption {
  label: string;
  value: string;
}
interface DataType {
  key: string;
  UserID: string;
  Name: string;
  Password: string;
  Email: string;
  UserLoginType: string;
  UserGroupID: string;
  Status: string;
  CreatedDt: string;
  CreatedBy: string;
  UpdatedDt: string;
  UpdatedBy: string;
}

interface DataType2 {
  key: string;
  UserGroupID: string;
  GroupName: string;
  GroupStatus: string;
  CreatedDt: string;
  CreatedBy: string;
  UpdatedDt: string;
  UpdatedBy: string;
}

//#1677ff
//none
//topLeft
//bottomRight
function App() {
  const textwidth = 200;
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading,SetLoading] = useState(true);
  
  const [UserList,SetUserList] = useState<DataType[]>([]);
  const [UserGroupMap,SetUserGroupMap] = useState({});
  const [UserGroupList,SetUserGroupList] = useState<DataType2[]>([]);
  const [UserGroupSelectList,SetUserGroupSelectList] = useState<SelectOption[]>([]);

  const [inputState,setInputState] = useState("");
  const [dialogTitle,setDialogTitle] = useState("User");

  const [LastUserID,SetLastUserID] = useState("");
  const [UserID,SetUserID] = useState("");
  const [UserName,SetUserName] = useState("");
  const [UserPassword,SetUserPassword] = useState("");
  const [UserEmail1,SetUserEmail1] = useState("");
  const [UserEmail2,SetUserEmail2] = useState("");
  const [UserLoginType,SetUserLoginType] = useState("");
  const [UserGroupID,SetUserGroupID] = useState("");
  const [UserShowGroupID,SetUserShowGroupID] = useState("");
  const [UserStatus,SetUserStatus] = useState("");
  const [isEdit,SetIsEdit] = useState(false);

  const [UserGroupID_,SetUserGroupID_] = useState("");
  const [UserGroupName,SetUserGroupName] = useState("");
  const [AdminTab,SetAdminTab] = useState("false");
  const [OrderTab,SetOrderTab] = useState("false");
  const [ReportTab,SetReportTab] = useState("false");
  const [OrderYesterday,SetOrderYesterday] = useState("false");
  const [OrderType,SetOrderType] = useState("00000000");
  const [UserGroupStatus,SetUserGroupStatus] = useState("1");

  const columns: ColumnsType<DataType> = [
    {
      title: '',
      dataIndex: 'UserID',
      key: 'UserID',
      render: (_:any,record:DataType) => <div></div>,
      width:"1%"
    },
    {
      title: 'User ID',
      dataIndex: 'UserID',
      key: 'UserID',
      render: (_:any,record:DataType) => <div>{record.UserID}</div>,
      sorter: (a, b) =>  a.UserID.localeCompare(b.UserID),
      defaultSortOrder: 'ascend',
      width:"12%"
    },
    {
      title: 'User Name',
      dataIndex: 'Name',
      key: 'Name',
      render: (_:any,record:DataType) => <div>{record.Name}</div>,
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      width:"20%"
    },
    {
      title: 'User Group',
      dataIndex: 'usergroup',
      key: 'usergroup',
      render: (_:any,record:DataType) => <div>{UserGroupMap[record.UserGroupID]}</div>,
      sorter: (a, b) => a.UserGroupID.localeCompare(b.UserGroupID),
      width:"12%"
    },
    {
      title: 'Email',
      key: 'Email',
      dataIndex: 'Email',
      render: (_:any,record:DataType) => <div>{record.Email}</div>,
      sorter: (a, b) => a.Email.localeCompare(b.Email),
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      render: (_:any,record:DataType) => <div>{(record.Status+"")==="1"?"Active":"Inactive"}</div>,
      sorter: (a, b) => a.Status.localeCompare(b.Status),
      width:"12%"
    },
  ];
  
  const columns2: ColumnsType<DataType2> = [
    {
      title: '',
      dataIndex: 'UserGroupID',
      key: 'UserGroupID',
      render: (_:any,record:DataType2) => <div></div>,
      width:"1%"
    },
    {
      title: 'UserGroupID',
      dataIndex: 'UserGroupID',
      key: 'UserGroupID',
      render: (_:any,record:DataType2) => <div>{record.UserGroupID}</div>,
      sorter: (a, b) =>  a.UserGroupID.toString().localeCompare(b.UserGroupID.toString()),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'GroupName',
      dataIndex: 'GroupName',
      key: 'GroupName',
      render: (_:any,record:DataType2) => <div>{record.GroupName}</div>,
      sorter: (a, b) => a.GroupName.localeCompare(b.GroupName),
    },
    {
      title: 'GroupStatus',
      dataIndex: 'GroupStatus',
      key: 'GroupStatus',
      render: (_:any,record:DataType2) => <div>{(record.GroupStatus+"")==="1"?"Active":"Inactive"}</div>,
      sorter: (a, b) => a.GroupStatus.localeCompare(b.GroupStatus),
    },
  ];
/*
{
    "itemObject": "[{\"UserID\":\"admin\",\"Name\":\"Administrator\",\"Password\":\"34263316\",\"Email\":\"\",\"UserLoginType\":\"All\",\"UserGroupID\":1,\"Status\":1,\"CreatedDt\":\"2023-07-11 17:34:01\",\"CreatedBy\":\"test\",\"UpdatedDt\":\"2023-07-11 17:34:03\",\"UpdatedBy\":\"test\",\"key\":0},
    {\"UserID\":\"airport\",\"Name\":\"Airport\",\"Password\":\"123\",\"Email\":\"\",\"UserLoginType\":\"All\",\"UserGroupID\":3,\"Status\":1,\"CreatedDt\":\"2024-04-26 12:01:22\",\"CreatedBy\":\"test\",\"UpdatedDt\":\"2024-04-26 12:01:25\",\"UpdatedBy\":\"test\",\"key\":1},
    {\"UserID\":\"david\",\"Name\":\"David\",\"Password\":\"123\",\"Email\":\"\",\"UserLoginType\":\"All\",\"UserGroupID\":1,\"Status\":1,\"CreatedDt\":\"2023-10-25 12:13:50\",\"CreatedBy\":\"test\",\"UpdatedDt\":\"2023-10-25 12:13:53\",\"UpdatedBy\":\"test\",\"key\":2},
    {\"UserID\":\"mapking\",\"Name\":\"mapking\",\"Password\":\"34263316\",\"Email\":\"\",\"UserLoginType\":\"All\",\"UserGroupID\":1,\"Status\":1,\"CreatedDt\":\"2023-11-14 12:24:58\",\"CreatedBy\":\"test\",\"UpdatedDt\":\"2023-11-14 12:25:01\",\"UpdatedBy\":\"test\",\"key\":3},
    {\"UserID\":\"report\",\"Name\":\"Report\",\"Password\":\"123\",\"Email\":\"\",\"UserLoginType\":\"All\",\"UserGroupID\":2,\"Status\":1,\"CreatedDt\":\"2024-04-26 12:01:59\",\"CreatedBy\":\"test\",\"UpdatedDt\":\"2024-04-26 12:02:02\",\"UpdatedBy\":\"test\",\"key\":4},
    {\"UserID\":\"test\",\"Name\":\"Test 123\",\"Password\":\"34263316\",\"Email\":\"\",\"UserLoginType\":\"All\",\"UserGroupID\":1,\"Status\":1,\"CreatedDt\":\"2023-07-07 18:08:38\",\"CreatedBy\":\"test\",\"UpdatedDt\":\"2023-07-07 18:08:41\",\"UpdatedBy\":\"test\",\"key\":5},
    {\"UserID\":\"test2\",\"Name\":\"Test 2\",\"Password\":\"34263316\",\"Email\":\"\",\"UserLoginType\":\"All\",\"UserGroupID\":1,\"Status\":1,\"CreatedDt\":\"2023-07-26 17:37:03\",\"CreatedBy\":\"test\",\"UpdatedDt\":\"2023-07-26 17:37:05\",\"UpdatedBy\":\"test\",\"key\":6},
    {\"UserID\":\"test3\",\"Name\":\"Test3\",\"Password\":\"34263316\",\"Email\":\"\",\"UserLoginType\":\"All\",\"UserGroupID\":1,\"Status\":1,\"CreatedDt\":\"2023-09-27 12:55:27\",\"CreatedBy\":\"test\",\"UpdatedDt\":\"2023-09-27 12:55:32\",\"UpdatedBy\":\"test\",\"key\":7},
    {\"UserID\":\"test4\",\"Name\":\"test4\",\"Password\":\"34263316\",\"Email\":\"\",\"UserLoginType\":\"All\",\"UserGroupID\":1,\"Status\":1,\"CreatedDt\":\"2023-09-27 12:55:27\",\"CreatedBy\":\"test\",\"UpdatedDt\":\"2023-09-27 12:55:27\",\"UpdatedBy\":\"test\",\"key\":8}]",
    "result": "200"
}
*/
  const GetUser = () =>{
    var para0 = "";
    var para1 = "action=GetUser";
    var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
    var para3 = para1 + para2;
    //ConsoleLog(para3)
    GetData(para3,
        data => {
          //ConsoleLog(data)
        if(data["result"] === "200"){
          var list = JSON.parse(data["itemObject"])
          SetUserList(list)
        }else{
          oneButtonConfirmDialog("Error","Please Login Again",()=>{})
        }
        SetLoading(false)
        },
        ()=>{oneButtonConfirmDialog("Network Error","",()=>{});SetLoading(false)}
    )
}
/*
{
    "itemObject": "[{\"UserGroupID\":1,\"GroupName\":\"Administrator\",\"GroupStatus\":1,\"CreatedDt\":\"2023-07-11 17:34:01\",\"CreatedBy\":\"test\",\"UpdatedDt\":\"2023-07-11 17:34:03\",\"UpdatedBy\":\"test\",\"key\":0},
    {\"UserGroupID\":3,\"GroupName\":\"Airport\",\"GroupStatus\":1,\"CreatedDt\":\"2024-04-26 12:01:22\",\"CreatedBy\":\"test\",\"UpdatedDt\":\"2024-04-26 12:01:25\",\"UpdatedBy\":\"test\",\"key\":1},
    {\"UserGroupID\":1,\"GroupName\":\"David\",\"GroupStatus\":1,\"CreatedDt\":\"2023-10-25 12:13:50\",\"CreatedBy\":\"test\",\"UpdatedDt\":\"2023-10-25 12:13:53\",\"UpdatedBy\":\"test\",\"key\":2},
    {\"UserGroupID\":1,\"GroupName\":\"mapking\",\"GroupStatus\":1,\"CreatedDt\":\"2023-11-14 12:24:58\",\"CreatedBy\":\"test\",\"UpdatedDt\":\"2023-11-14 12:25:01\",\"UpdatedBy\":\"test\",\"key\":3},
    {\"UserGroupID\":2,\"GroupName\":\"Report\",\"GroupStatus\":1,\"CreatedDt\":\"2024-04-26 12:01:59\",\"CreatedBy\":\"test\",\"UpdatedDt\":\"2024-04-26 12:02:02\",\"UpdatedBy\":\"test\",\"key\":4},
    {\"UserGroupID\":1,\"GroupName\":\"Test 123\",\"GroupStatus\":1,\"CreatedDt\":\"2023-07-07 18:08:38\",\"CreatedBy\":\"test\",\"UpdatedDt\":\"2023-07-07 18:08:41\",\"UpdatedBy\":\"test\",\"key\":5},
    {\"UserGroupID\":1,\"GroupName\":\"Test 2\",\"GroupStatus\":1,\"CreatedDt\":\"2023-07-26 17:37:03\",\"CreatedBy\":\"test\",\"UpdatedDt\":\"2023-07-26 17:37:05\",\"UpdatedBy\":\"test\",\"key\":6},
    {\"UserGroupID\":1,\"GroupName\":\"Test3\",\"GroupStatus\":1,\"CreatedDt\":\"2023-09-27 12:55:27\",\"CreatedBy\":\"test\",\"UpdatedDt\":\"2023-09-27 12:55:32\",\"UpdatedBy\":\"test\",\"key\":7},
    {\"UserGroupID\":1,\"GroupName\":\"test4\",\"GroupStatus\":1,\"CreatedDt\":\"2023-09-27 12:55:27\",\"CreatedBy\":\"test\",\"UpdatedDt\":\"2023-09-27 12:55:27\",\"UpdatedBy\":\"test\",\"key\":8}]",
    "result": "200"
}
*/
const GetUserGroup = () =>{
  var para0 = "";
  var para1 = "action=GetUserGroup";
  var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
  var para3 = para1 + para2;
  ConsoleLog(para3)
  GetData(para3,
      data => {
        ConsoleLog(data)
      if(data["result"] === "200"){
        var list = JSON.parse(data["itemObject"])
        var selectItemList:SelectOption[] = []
        var map = {}
        for(var k = 0;k<list.length;k++){
          selectItemList.push({label: list[k]['GroupName'], value: list[k]['UserGroupID']})
          map[""+list[k]['UserGroupID']] = list[k]['GroupName']
        }
        SetUserGroupMap(map)
        SetUserGroupList(list)
        SetUserGroupSelectList(selectItemList)
      }else{
        oneButtonConfirmDialog("Error","Please Login Again",()=>{})
      }
      SetLoading(false)
      },
      ()=>{oneButtonConfirmDialog("Network Error","",()=>{});SetLoading(false)}
  )
}
/*
{
    "itemObject": "[
      {\"UserGroupID\":1,
      \"GroupName\":\"Admin\",
      \"GroupStatus\":1,
      \"Function\":\"AdminTab\",\"Value\":\"true\",
      \"UpdatedDt\":\"2024-04-25 17:32:45\",\"UpdatedBy\":\"admin\",\"key\":0},
      {\"UserGroupID\":1,
      \"GroupName\":\"Admin\",
      \"GroupStatus\":1,
      \"Function\":\"OrderTab\",\"Value\":\"true\",
      \"UpdatedDt\":\"2024-04-25 17:32:33\",\"UpdatedBy\":\"admin\",\"key\":1},
      {\"UserGroupID\":1,\"GroupName\":\"Admin\",\"GroupStatus\":1,
      \"Function\":\"OrderType\",\"Value\":\"11111111\",
      \"UpdatedDt\":\"2024-04-26 11:32:41\",\"UpdatedBy\":\"admin\",\"key\":2},
      {\"UserGroupID\":1,
      \"GroupName\":\"Admin\",
      \"GroupStatus\":1,
      \"Function\":\"OrderYesterday\",\"Value\":\"true\",
      \"UpdatedDt\":\"2024-04-26 11:59:56\",\"UpdatedBy\":\"admin\",\"key\":3},
      {\"UserGroupID\":1,
      \"GroupName\":\"Admin\",
      \"GroupStatus\":1,
      \"Function\":\"ReportTab\",\"Value\":\"true\",
      \"UpdatedDt\":\"2024-04-25 17:32:04\",\"UpdatedBy\":\"admin\",\"key\":4}]",
    "result": "200"
}
*/
const GetUserRight = (_groupid) =>{
  var para0 = JSON.stringify(
    {
      UserGroupID:_groupid,
    }   
  )
  var para1 = "action=GetUserRight";
  var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
  var para3 = para1 + para2 + "&itemObject=" + para0;;
  ConsoleLog(para3)
  GetData(para3,
      data => {
        ConsoleLog(data)
      if(data["result"] === "200"){
        var list = JSON.parse(data["itemObject"])
        for(var k = 0;k<list.length;k++){
          if(list[k]["Function"] === "AdminTab"){
            SetAdminTab(list[k]["Value"])
            SetUserGroupStatus(list[k]["GroupStatus"])
            SetUserGroupName(list[k]["GroupName"])
            SetUserGroupID_(list[k]["UserGroupID"])
          }else if(list[k]["Function"] === "OrderTab"){
            SetOrderTab(list[k]["Value"])
          }else if(list[k]["Function"] === "ReportTab"){
            SetReportTab(list[k]["Value"])
          }else if(list[k]["Function"] === "OrderYesterday"){
            SetOrderYesterday(list[k]["Value"])
          }else if(list[k]["Function"] === "OrderType"){
            SetOrderType(list[k]["Value"])
          }
        }
        SetIsEdit(true)
        setIsModalOpen2(true)

      }else{
        oneButtonConfirmDialog("Error","Please Login Again",()=>{})
      }
      SetLoading(false)
      },
      ()=>{oneButtonConfirmDialog("Network Error","",()=>{});SetLoading(false)}
  )
}
const CreateUser = (_lastuserid,_userid,_name,_password,_email,_userlogintype,_usergroup,_status) =>{
  var para0 = JSON.stringify(
    {
      UserID:_userid,
      Name:_name,
      Password:_password,
      Email:_email,
      UserLoginType:_userlogintype,
      UserGroupID:_usergroup,
      Status:_status
    }   
  )
  var para1 = "action=CreateUser";
  if(isEdit){
    para1 = "action=UpdateUser";
    para0 = JSON.stringify(
      {
        LastUserID:_userid,
        UserID:_userid,
        Name:_name,
        Password:_password,
        Email:_email,
        UserLoginType:_userlogintype,
        UserGroupID:_usergroup,
        Status:_status
      }   
    )
  }
  var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
  var para3 = para1 + para2 + "&itemObject=" + para0;
  GetData(para3,
      data => {
        ConsoleLog(data)
      if(data["result"] === "200"){
        GetUser()
        oneButtonConfirmDialog("User Record Saved","",()=>{setIsModalOpen1(false)})
      }else if(data["result"] === "401"){
        oneButtonConfirmDialog(`Error ${data["result"]}`,"Duplicate User ID",()=>{})
      }else{
        oneButtonConfirmDialog(`Error ${data["result"]}`,"Please Login Again",()=>{})
      }
      SetLoading(false)
      },
      ()=>{oneButtonConfirmDialog("Network Error","",()=>{});SetLoading(false)}
  )
}
const CreateUserGroup = (_UserGroupID,_GroupName,_AdminTab,_OrderTab,_ReportTab,_OrderYesterday,_OrderType,_GroupStatus) =>{
  var para0 = JSON.stringify(
    {
      UserGroupID:_UserGroupID,
      GroupName:_GroupName,
      AdminTab:_AdminTab,
      OrderTab:_OrderTab,
      ReportTab:_ReportTab,
      OrderYesterday:_OrderYesterday,
      OrderType:_OrderType,
      GroupStatus:_GroupStatus
    }   
  )
  var para1 = "action=CreateUserGroup";
  if(isEdit){
    para1 = "action=UpdateUserGroup";
  }
  var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
  var para3 = para1 + para2 + "&itemObject=" + para0;
  GetData(para3,
      data => {
        ConsoleLog(data)
      if(data["result"] === "200"){
        GetUserGroup()
        oneButtonConfirmDialog("UserGroup Record Saved","",()=>{setIsModalOpen2(false)})
      }else{
        oneButtonConfirmDialog(`Error ${data["result"]}`,"Please Login Again",()=>{})
      }
      SetLoading(false)
      },
      ()=>{oneButtonConfirmDialog("Network Error","",()=>{});SetLoading(false)}
  )
}
useEffect(
  ()=>{
      const timer = setTimeout(()=>{
        GetUser()
        GetUserGroup()
      },500)
      return () => clearTimeout(timer)
  }
,[]);
//<p style={{height:500,overflow:"scroll"}}>
  const dialog1 = <Modal title={dialogTitle} open={isModalOpen1} onOk={()=>{}} onCancel={()=>{setIsModalOpen1(false)}} confirmLoading={confirmLoading}
    width={500}
    bodyStyle={{height:380}}
    footer={[
      <Button key="back" onClick={()=>{setIsModalOpen1(false)}}>
        Return
      </Button>,

      <Button key="submit" type="primary" loading={confirmLoading} onClick={
          ()=>{
            if(UserID !== "" && UserPassword !== ""){
              confirmDialog(isEdit?"Edit User Data?":"Create User?","",()=>{
                CreateUser(LastUserID,UserID,UserName,UserPassword,UserEmail1+"@"+UserEmail2,"All",UserGroupID,UserStatus)
              },()=>{})
            }else{
              oneButtonConfirmDialog("UserID and Password can not be blank","",()=>{})
            }
          }
        }>
        Submit
      </Button>,
    ]}

    >
      <div>
      {/*<p style={{height:500,overflow:"scroll"}}> */}
      <Space
        direction="vertical"
        style={{ width: 450,marginTop:20,marginLeft:10,marginRight:10 }}
      >   
          <Row>
            <Col span={8}><Text style={{ width: textwidth }}>User ID:</Text></Col>
            <Col span={16}><Input style={{ width: "99%" }} value={UserID} onChange={(e)=>{SetUserID(e.target.value)}}/></Col>
          </Row>
          <Row>
            <Col span={8}><Text style={{ width: textwidth }}>User Name:</Text></Col>
            <Col span={16}><Input style={{ width: "99%" }} value={UserName} onChange={(e)=>{SetUserName(e.target.value)}}/></Col>
          </Row>
          <Row>
            <Col span={8}><Text style={{ width: textwidth }}>Password:</Text></Col>
            <Col span={16}><Input style={{ width: "99%" }} value={UserPassword} onChange={(e)=>{SetUserPassword(e.target.value)}}/></Col>
          </Row>
          <Row>
            <Col span={8}><Text style={{ width: textwidth }}>Email:</Text></Col>
            <Col span={6}><Input value={UserEmail1} onChange={(e)=>{SetUserEmail1(e.target.value)}} style={{ width: "99%" }}/></Col>
            <Col span={1} style={{ paddingTop:5 }}><Text>@</Text></Col>
            <Col span={9}><Input value={UserEmail2} onChange={(e)=>{SetUserEmail2(e.target.value)}} style={{ width: "99%" }}/></Col>
          </Row>
          <Row>
            <Col span={8}><Text style={{ width: textwidth }}>User Group:</Text></Col>
            <Col span={16}>
            <Select showSearch={true} optionFilterProp="label" value={UserGroupMap[UserGroupID]} onChange={(e)=>{
                // for(var k=0;k<UserGroupSelectList.length;k++){
                //   ConsoleLog(UserGroupSelectList[k]["value"]+":"+e.toString())
                //   if(UserGroupSelectList[k]["value"].toString() === e.toString()){
                //     ConsoleLog(UserGroupSelectList[k]["value"]+":"+UserGroupID)
                //     SetUserShowGroupID(UserGroupSelectList[k]["label"])
                //     break
                //   }
                // }
                SetUserGroupID(e.toString())
              }} style={{ width: "99%" }} options={UserGroupSelectList}></Select>
            </Col>
          </Row>
          <Row>
            <Col span={8}><Text style={{ width: textwidth }}>Status:</Text></Col>
            <Col span={16}>
              <Select value={UserStatus.toString()==="1"?"Active":"Inactive"} onChange={(e)=>{SetUserStatus(e.toString())}} style={{ width: "99%" }}>
                <Select.Option value="1">Active</Select.Option>
                <Select.Option value="0">Inactive</Select.Option>
              </Select>
            </Col>
          </Row>
          <Space
            direction="horizontal"
          >

          </Space>
          
      </Space>
    {/*</p>*/}
    </div>
  </Modal>

const dialog2 = <Modal title={dialogTitle} open={isModalOpen2} onOk={()=>{}} onCancel={()=>{setIsModalOpen2(false)}} confirmLoading={confirmLoading}
width={500}
bodyStyle={{height:380}}
footer={[
  <Button key="back" onClick={()=>{setIsModalOpen2(false)}}>
    Return
  </Button>,

  <Button key="submit" type="primary" loading={confirmLoading} onClick={
      ()=>{
        if(UserGroupName !== ""){
          confirmDialog(isEdit?"Edit UserGroup Data?":"Create UserGroup?","",()=>{
            CreateUserGroup(UserGroupID_,UserGroupName,AdminTab,OrderTab,ReportTab,OrderYesterday,OrderType,UserGroupStatus)
          },()=>{})
        }else{
          oneButtonConfirmDialog("UserGroupName can not be blank","",()=>{})
        }
      }
    }>
    Submit
  </Button>,
]}

>
  <div>
  {/*<p style={{height:500,overflow:"scroll"}}> */}
  <Space
    direction="vertical"
    style={{ width: 450,marginTop:20,marginLeft:0,marginRight:10 }}
  >   
      <Row>
        <Col span={6}><Text style={{ width: textwidth }}>Group Name:</Text></Col>
        <Col span={18}><Input style={{ width: "99%" }} value={UserGroupName} onChange={(e)=>{SetUserGroupName(e.target.value)}}/></Col>
      </Row>
      <Row>
        <Col span={6}><Text style={{ width: textwidth }}>View Page:</Text></Col>
        <Col span={18}>
          <Row>
          <Checkbox style={{marginTop:5,paddingLeft:10}} checked={OrderType.substring(0,1) === "1"} onChange={(e)=>{SetOrderType( ((e.target.checked)?"1":"0" ) +OrderType.substring(1,8)); }}>Outstanding</Checkbox>
          <Checkbox style={{marginTop:5,paddingLeft:10}} checked={OrderType.substring(1,2) === "1"} onChange={(e)=>{SetOrderType(OrderType.substring(0,1) + ((e.target.checked)?"1":"0" ) +OrderType.substring(2,8)); }}>In-Progress</Checkbox>
          <Checkbox style={{marginTop:5,paddingLeft:10}} checked={OrderType.substring(2,3) === "1"} onChange={(e)=>{SetOrderType(OrderType.substring(0,2) + ((e.target.checked)?"1":"0" ) +OrderType.substring(3,8)); }}>Complete</Checkbox>
          <Checkbox style={{marginTop:5,paddingLeft:10}} checked={OrderType.substring(3,4) === "1"} onChange={(e)=>{SetOrderType(OrderType.substring(0,3) + ((e.target.checked)?"1":"0" ) +OrderType.substring(4,8)); }}>Noshow Order</Checkbox>
          </Row>
          <Row>
            <Checkbox style={{marginTop:5,paddingLeft:10}} checked={OrderType.substring(4,5) === "1"} onChange={(e)=>{SetOrderType(OrderType.substring(0,4) + ((e.target.checked)?"1":"0" ) +OrderType.substring(5,8)); }}>Cancel</Checkbox>
            <Checkbox style={{marginTop:5,paddingLeft:10}} checked={OrderType.substring(5,6) === "1"} onChange={(e)=>{SetOrderType(OrderType.substring(0,5) + ((e.target.checked)?"1":"0" ) +OrderType.substring(6,8)); }}>Uploaded</Checkbox>
            <Checkbox style={{marginTop:5,paddingLeft:10}} checked={OrderType.substring(6,7) === "1"} onChange={(e)=>{SetOrderType(OrderType.substring(0,6) + ((e.target.checked)?"1":"0" ) +OrderType.substring(7,8)); }}>Departure</Checkbox>
            <Checkbox style={{marginTop:5,paddingLeft:10}} checked={OrderType.substring(7,8) === "1"} onChange={(e)=>{SetOrderType(OrderType.substring(0,7) + ((e.target.checked)?"1":"0" )); }}>Arrival</Checkbox> 
          </Row>   
        </Col>    
      </Row>
      <Row>
        <Col span={6}><Text style={{ width: textwidth }}>Order tab:</Text></Col>
        <Col span={18}>
        <Checkbox style={{marginTop:5,paddingLeft:10}} checked={OrderTab === "true"} onChange={(e)=>{SetOrderTab((e.target.checked)?"true":"false");}}>OrderTab</Checkbox>
        <Checkbox style={{marginTop:5,paddingLeft:10}} checked={ReportTab === "true"} onChange={(e)=>{SetReportTab((e.target.checked)?"true":"false");}}>ReportTab</Checkbox>
        <Checkbox style={{marginTop:5,paddingLeft:10}} checked={AdminTab === "true"} onChange={(e)=>{SetAdminTab((e.target.checked)?"true":"false");}}>AdminTab</Checkbox>
        <Checkbox style={{marginTop:5,paddingLeft:10}} checked={OrderYesterday === "true"} onChange={(e)=>{SetOrderYesterday((e.target.checked)?"true":"false");}}>Previous Order</Checkbox>
        </Col>   
      </Row>
      <Row>
        <Col span={6}><Text style={{ width: textwidth }}>Status:</Text></Col>
        <Col span={18}>
          <Select value={UserGroupStatus.toString()==="1"?"Active":"Inactive"} onChange={(e)=>{SetUserGroupStatus(e.toString())}} style={{ width: "99%" }}>
            <Select.Option value="1">Active</Select.Option>
            <Select.Option value="0">Inactive</Select.Option>
          </Select>
        </Col>
      </Row>
      <Space
        direction="horizontal"
      >

      </Space>
      
  </Space>
{/*</p>*/}
</div>
</Modal>

  const table01 = useMemo(()=>{
    return (
      <Table style={{width:"100%",height:765,padding:'0vh'}} scroll={{ x: "100%", y: 720 }} columns={columns} dataSource={UserList} pagination={{ position: ['none','none'],pageSize: 1000 }}
      onRow={(r) => ({
        onDoubleClick: () => {
          SetIsEdit(true)
          SetUserID(r.UserID)
          SetLastUserID(r.UserID)
          SetUserName(r.Name)
          SetUserPassword(r.Password)
          const emailsplit = r.Email.split("@")
          if(emailsplit.length>1){
            SetUserEmail1(emailsplit[0])
            SetUserEmail2(emailsplit[1])
          }else{
            SetUserEmail1("")
            SetUserEmail2("")
          }
          SetUserLoginType(r.UserLoginType)
          ConsoleLog(r.UserGroupID)
          SetUserGroupID(r.UserGroupID)
          // for(var k=0;k<UserGroupSelectList.length;k++){
          //   if(UserGroupSelectList[k]["value"] === r.UserGroupID){
          //     ConsoleLog(UserGroupSelectList[k]["value"]+":"+UserGroupID)
          //     SetUserShowGroupID(UserGroupSelectList[k]["label"])
          //   }
          // }
          SetUserStatus(r.Status)
          setIsModalOpen1(true)
        },
      })}
      />
    )
  },[UserList])

  const table02 = useMemo(()=>{
    return (
      <Table style={{width:"100%",height:765,padding:'0vh'}} scroll={{ x: "100%", y: 720 }} columns={columns2} dataSource={UserGroupList} pagination={{ position: ['none','none'],pageSize: 1000 }}
      onRow={(r) => ({
        onClick: () => {
          GetUserRight(r.UserGroupID)
        },
      })}
      />
    )
  },[UserGroupSelectList])
  return (
    <div className='commonContent' style={{background:'#ffffff',height:800,minWidth:1440,padding:'0vh',overflow:"hidden"}}>
      <Row>
        <Col span={15}>
          {table01}
        </Col>
        <Col span={9}>
          {table02}
        </Col>
      </Row>
      <div style={{paddingLeft:'1vw',paddingRight:'1vw'}}> 
        <Button type="primary" 
          style={{width:100,position: 'relative',float: 'right',paddingRight:'1vw'}}
          onClick={()=>{
            SetIsEdit(false)
            SetLastUserID("")
            SetUserID("")
            SetUserName("")
            SetUserPassword("")
            SetUserEmail1("")
            SetUserEmail2("")
            SetUserLoginType("")
            SetUserGroupID("0")
            SetUserStatus("1")
            setIsModalOpen1(true)
            }}>
          Add
        </Button>
      </div>
      {dialog1}
      {dialog2}
    </div>
  );
}

export default App;
