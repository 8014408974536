import React, { useState } from 'react';
import { SmileOutlined } from '@ant-design/icons';
import { Button,Modal,Timeline } from 'antd';
import './Tabs.css';


function App() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const dialog1 = <Modal title="Basic Modal" open={isModalOpen1} onOk={()=>{setIsModalOpen1(false)}} onCancel={()=>{setIsModalOpen1(false)}}>
  <p>Some contents...</p>
  <p>Some contents...</p>
  <p>Some contents...</p>
  </Modal>

  const dialog2 = <Modal title="Timeline" open={isModalOpen2} onOk={()=>{setIsModalOpen2(false)}} onCancel={()=>{setIsModalOpen2(false)}}>
    
      <Timeline
        items={[
          {
            color: 'green',
            children: 'Create a services site 2015-09-01',
          },
          {
            color: 'green',
            children: 'Create a services site 2015-09-01',
          },
          {
            color: 'red',
            children: (
              <>
                <p>Solve initial network problems 1</p>
                <p>Solve initial network problems 2</p>
                <p>Solve initial network problems 3 2015-09-01</p>
              </>
            ),
          },
          {
            children: (
              <>
                <p>Technical testing 1</p>
                <p>Technical testing 2</p>
                <p>Technical testing 3 2015-09-01</p>
              </>
            ),
          },
          {
            color: 'gray',
            children: (
              <>
                <p>Technical testing 1</p>
                <p>Technical testing 2</p>
                <p>Technical testing 3 2015-09-01</p>
              </>
            ),
          },
          {
            color: 'gray',
            children: (
              <>
                <p>Technical testing 1</p>
                <p>Technical testing 2</p>
                <p>Technical testing 3 2015-09-01</p>
              </>
            ),
          },
          {
            color: '#00CCFF',
            dot: <SmileOutlined />,
            children: <p>Custom color testing</p>,
          },
        ]}
      />

  </Modal>
  return (
    <div className='commonContent'>
      <Button type="primary" onClick={()=>{setIsModalOpen1(true)}}>
        Open Modal
      </Button>
      <Button type="primary" onClick={()=>{setIsModalOpen2(true)}}>
        TimeLine
      </Button>
      {dialog1}
      {dialog2}
    </div>
  );
}

export default App;
