import React from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Col, Row, Menu, DatePicker, Input} from 'antd';
import {Header} from '../Common/commonui.tsx'
import {createBrowserRouter,RouterProvider,useNavigate } from "react-router-dom";

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuProps['items'] = [
    getItem('Driver', 'grp', null, 
    [
        getItem('Ben Wong', '13'), 
        getItem('Sammy Lee', '14'),
        getItem('Tom Chan', '15'),
    ],
     'group'),
  ];
  const { TextArea } = Input;
  const { Search } = Input;
function App() {
  let navigate = useNavigate();
  return (
    <div className='content' style={{minWidth:1440,height:910,padding:'0vh'}}>
      {Header(navigate)}
      <div style={{minWidth:1440,height:840,background:'#1677ff',paddingTop:5,paddingLeft:5,paddingRight:5,paddingBottom:5}}>
        <div style={{minWidth:1440,height:840,background:'#ffffff',paddingTop:'0vh',paddingLeft:'0vh',paddingRight:'0vh',paddingBottom:'0vh'}}>
        <Row>
                <Col span={4}>
                  <Search style={{height:40,width: 250,marginLeft:'1vw',marginTop:'1vh' }} placeholder="input driver name" onSearch={()=>{}} enterButton />
                  <div style={{height:760,background:'#ffffff',paddingTop:'0vh',paddingLeft:'0vh',paddingRight:'0vh',paddingBottom:'0vh'}}>
                    <Menu
                      onClick={()=>{}}
                      style={{ position:"absolute",float:"right"}}
                      defaultSelectedKeys={['1']}
                      defaultOpenKeys={['sub1']}
                      mode="inline"
                      items={items}
                    />
                  </div>
                </Col>
                <Col span={20}>
                  <div style={{height:800,background:'#1677ff',paddingTop:'0vh',paddingLeft:'0vh',paddingRight:'0vh',paddingBottom:'0vh'}}>
                    <div style={{height:795,background:'#ffffff',marginLeft:5,padding:5}}>
                      <div>
                        <Row>
                          <Col span={24}>
                          <DatePicker />
                          </Col>
                        </Row>
                        <Row style={{background:'#ffffff',height:'68vh',marginLeft:'0vh',marginTop:'5vh'}}>
                          <Col span={24}>
                            <h3>2023-07-04</h3>
                            <div>Ben Wong : Hi 17:05</div>
                            <div>Administrator : Hi 17:06</div>
                            <div>Ben Wong : Arrive to Pick up point 17:10</div>
                            <div>Administrator : OK 17:12</div>
                            </Col>
                        </Row>
                        <Row style={{background:'#ffffff',height:'10vh'}}>
                          <Col span={22}>
                            <TextArea rows={4} />
                          </Col>
                          <Col span={2}>
                            <Button type="primary" 
                                style={{height:'10vh',width:100,position: 'relative',float: 'right',margin:'1px'}}
                                onClick={()=>{}}>
                                Send
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
            </Row>
        </div>
      </div>
    </div>
  );
}

export default App;
