import React, { useState,useEffect } from 'react';
import { Image,Input,Button, DatePicker,Space,Spin,Form,Layout,Typography } from 'antd';
import './SignIn.css';
import {createBrowserRouter,RouterProvider,useNavigate,useParams } from "react-router-dom";
import {confirmDialog,oneButtonConfirmDialog} from '../Common/commonui.tsx'
import Common,{emptyCache,CallAPI,GetData,ConsoleLog,versionNumber} from '../Common/commonfunction.tsx';
import { msalConfig,loginRequest } from './authConfig';
import { useMsal,useIsAuthenticated } from "@azure/msal-react";
import { PublicClientApplication, EventType } from '@azure/msal-browser';
const { Header, Content, Footer, Sider } = Layout;

const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  const { code } = useParams();
  ConsoleLog(code);
  const { Text, Link } = Typography;
  useEffect(
    () => {
      setTimeout(() => {
        if(true){
          emptyCache();
        }
      }, 1000);
    }
  ,[]);
  let navigate = useNavigate();
  const [User,SetUser] = useState("");
  const [Password,SetPassword] = useState("");
  const [loading,SetLoading] = useState(false);
  const [LoginCalled,SetLoginCalled] = useState(false);
  const showConfirm = () =>{oneButtonConfirmDialog("Login Error","test",
  ()=>{
  
  });
}
/*"right": "[{\"UserGroupID\":1,\"GroupName\":\"Admin\",\"GroupStatus\":1,\"Function\":\"AdminTab\",\"Value\":\"TRUE\",\"UpdatedDt\":\"2024-04-25 17:32:45\",\"UpdatedBy\":\"admin\"},
{\"UserGroupID\":1,\"GroupName\":\"Admin\",\"GroupStatus\":1,\"Function\":\"OrderTab\",\"Value\":\"TRUE\",\"UpdatedDt\":\"2024-04-25 17:32:33\",\"UpdatedBy\":\"admin\"},
{\"UserGroupID\":1,\"GroupName\":\"Admin\",\"GroupStatus\":1,\"Function\":\"OrderType\",\"Value\":\"All\",\"UpdatedDt\":\"2024-04-26 11:32:41\",\"UpdatedBy\":\"admin\"},
{\"UserGroupID\":1,\"GroupName\":\"Admin\",\"GroupStatus\":1,\"Function\":\"OrderYesterday\",\"Value\":\"TRUE\",\"UpdatedDt\":\"2024-04-26 11:59:56\",\"UpdatedBy\":\"admin\"},
{\"UserGroupID\":1,\"GroupName\":\"Admin\",\"GroupStatus\":1,\"Function\":\"ReportTab\",\"Value\":\"TRUE\",\"UpdatedDt\":\"2024-04-25 17:32:04\",\"UpdatedBy\":\"admin\"}]"*/
  const signInFunc = ()=>{
    SetLoading(true)
    var para0 = JSON.stringify({UserID:User,Password:Password,IMEI:"0",System:"Web"});
    var para1 = "action=Login";
    var para2 = para1 + "&itemObject=" + para0;
    GetData(para2,
      data => {
        ConsoleLog(data)
        if(data["result"] === "200"){
          localStorage.setItem("DCHSelectedOrderSummaryDate",(new Date().toISOString()).substring(0,10))
          localStorage.setItem('DCHtoken',data["token"])
          localStorage.setItem('DCHuser',User)

          // localStorage.setItem(`DCHOrderYesterday`,"true");
          // localStorage.setItem(`DCHOrderType`,"11111111");
          // localStorage.setItem(`DCHAdminTab`,"true")
          // localStorage.setItem(`DCHReportTab`,"true")
          // localStorage.setItem(`DCHOrderTab`,"true")

          var list = JSON.parse(data["right"])
          const listszie = list.length
          for(var i = 0;i<listszie;i++){
            ConsoleLog(list[i]["Function"]+":"+list[i]["Value"])
            localStorage.setItem(`DCH${list[i]["Function"]}`,list[i]["Value"])
          }
          if(localStorage.getItem(`DCHOrderTab`) === "false"){
            if(localStorage.getItem(`DCHReportTab`) === "true"){
              navigate("/Report")
            }else if(localStorage.getItem(`DCHAdminTab`) === "true"){
              navigate("/orders")
            }
          }else{
            navigate("/orders")
          }
          //navigate("/orders")
        }else{
          oneButtonConfirmDialog("Login Error","",
            ()=>{
            
            })
        }
        SetLoading(false)
      },
      () => {
        oneButtonConfirmDialog("Network Error","",
        ()=>{
        
        })
        SetLoading(false)
      },
      ()=>{oneButtonConfirmDialog("Network Error","",()=>{});SetLoading(false)}
    );
  }

  const WindowLogin = (emailString,tokenString)=>{
    SetLoading(true)
    var para0 = JSON.stringify({Email:emailString,Token:tokenString,System:"Web"});
    var para1 = "action=WindowLogin";
    var para2 = para1 + "&itemObject=" + para0;
    GetData(para2,
      data => {
        ConsoleLog(data)
        if(data["result"] === "200"){
          localStorage.setItem("DCHSelectedOrderSummaryDate",(new Date().toISOString()).substring(0,10))
          localStorage.setItem('DCHtoken',data["token"])
          localStorage.setItem('DCHuser',User)

          var list = JSON.parse(data["right"])
          const listszie = list.length
          for(var i = 0;i<listszie;i++){
            ConsoleLog(list[i]["Function"]+":"+list[i]["Value"])
            localStorage.setItem(`DCH${list[i]["Function"]}`,list[i]["Value"])
          }
          if(localStorage.getItem(`DCHOrderTab`) === "false"){
            if(localStorage.getItem(`DCHReportTab`) === "true"){
              navigate("/Report")
            }else if(localStorage.getItem(`DCHAdminTab`) === "true"){
              navigate("/orders")
            }
          }else{
            navigate("/orders")
          }
          //navigate("/orders")
        }else{
          oneButtonConfirmDialog("Login Error","",
            ()=>{
            
            })
        }
        SetLoading(false)
      },
      () => {
        oneButtonConfirmDialog("Network Error","",
        ()=>{
        
        })
        SetLoading(false)
      },
      ()=>{oneButtonConfirmDialog("Network Error","",()=>{});SetLoading(false)}
    );
  }

  msalInstance.handleRedirectPromise().then(authResult=>{
    // Check if user signed in 
    
    // ConsoleLog(authResult)
    // ConsoleLog(authResult.account)
    // ConsoleLog(authResult.accessToken)
    // ConsoleLog(authResult.idToken)
    // const account = msalInstance.getActiveAccount();
    // if(!account){
    //   // redirect anonymous user to login page 
    //   //msalInstance.loginRedirect();
    //   ConsoleLog("no account");
    // }else{
    //   ConsoleLog("ssal get start");
    //   ConsoleLog(account.username);
    //   ConsoleLog(account.environment);
    //   ConsoleLog(account.homeAccountId);
    //   ConsoleLog(account.idToken);
    //   ConsoleLog(account.idTokenClaims);
    //   ConsoleLog(account.localAccountId);
    //   ConsoleLog(account.name);
    //   ConsoleLog(account.nativeAccountId);
    //   ConsoleLog("ssal get end");
    // }
    if(!authResult.account){
      // redirect anonymous user to login page 
      //msalInstance.loginRedirect();
      ConsoleLog("no account");
    }else{
      ConsoleLog("ssal  start");
      // ConsoleLog(authResult.account.username);
      // ConsoleLog(authResult.account.environment);
      // ConsoleLog(authResult.account.homeAccountId);
      // ConsoleLog(authResult.account.idToken);
      // ConsoleLog(authResult.account.idTokenClaims);
      // ConsoleLog(authResult.account.localAccountId);
      // ConsoleLog(authResult.account.name);
      // ConsoleLog(authResult.account.nativeAccountId);
      if(authResult.account.username !== "" && authResult.account.idToken !== "" && !LoginCalled){
        SetLoginCalled(true)
        WindowLogin(authResult.account.username,authResult.idToken)
      }
      ConsoleLog("ssal  end");
    }
  }).catch(err=>{
    // TODO: Handle errors
    
    ConsoleLog(err);
  });
  // const isAuthenticated = useIsAuthenticated();
  // if(isAuthenticated){
  //   const account = msalInstance.getActiveAccount();
  //   if(!account){
  //     ConsoleLog("no account");
  //   }else{
  //     ConsoleLog("ssal get start");
  //     ConsoleLog(account.username);
  //     ConsoleLog(account.environment);
  //     ConsoleLog(account.homeAccountId);
  //     ConsoleLog(account.idToken);
  //     ConsoleLog(account.idTokenClaims);
  //     ConsoleLog(account.localAccountId);
  //     ConsoleLog(account.name);
  //     ConsoleLog(account.nativeAccountId);
  //     ConsoleLog("ssal get end");
  //   }
  // }
  //const { instance, accounts, inProgress } = useMsal();
  return (
    <div className='SignInContent2'>
      <div style={{paddingTop:"10vh"}}>
      <img src='dch.png' style={{height:200,width:"auto"}}/>
        <Spin size="large" spinning={loading} tip="Loading...">
          <Layout>
            <Header style={{ padding: 0, background: 'white'}}>
              <Text style={{fontSize:50,fontWeight:"bold"}}>Limousine Order Dispatch System</Text>
            </Header>
            <Content style={{ margin: '24px 16px 0', overflow: 'initial'}}>
              <Space direction = "vertical" align="center">
              <div style={{paddingTop:"3vh"}}/>
              <Text style={{fontSize:22,fontWeight:"bold"}}>Sign In</Text>
                <Form
                  layout="horizontal"
                  style={{width: 300}}
                >
                  <Form.Item label=""><Input placeholder="User" value={User} 
                    onChange={(e)=>{SetUser(e.target.value)}}
                    onPressEnter={()=>{signInFunc()}}
                  /></Form.Item>
                  <Form.Item label=""><Input.Password placeholder="Password" value={Password} 
                    onChange={(e)=>{SetPassword(e.target.value)}}
                    onPressEnter={()=>{signInFunc()}}
                  /></Form.Item>
                  <Form.Item ><Button className="login-form-button" type="primary" onClick={()=>{
                    signInFunc()
                  }}>Sign In</Button></Form.Item>
                  <Form.Item ><Button className="login-form-button" type="primary" onClick={()=>{
                    SetLoginCalled(false)
                    msalInstance.loginRedirect(loginRequest)
                    .then(function (loginResponse) {
                      //let accountId = loginResponse.account.homeAccountId;
                      ConsoleLog("Login SSO");
                      ConsoleLog(loginResponse.account.username)
                      ConsoleLog("Login SSO END");
                      // Display signed-in user content, call API, etc.
                    })
                    .catch((error) => ConsoleLog(error));
                    // msalInstance.loginPopup(loginRequest)
                    // .then(function (loginResponse) {
                    //   let accountId = loginResponse.account.homeAccountId;
                    //   console.log(accountId);
                    //   // Display signed-in user content, call API, etc.
                    // })
                    // .catch(function (error) {
                    //   //login failure
                    //   console.log(error);
                    // });
                  }}>Sign In By SSO</Button></Form.Item>
                </Form>
              </Space>
              <div className='TopPadding'>
                Version {versionNumber}
              </div>
            </Content>
            <Footer>DCH Mobility ©2023</Footer>
          </Layout>
        </Spin>
      </div>
    </div>
  );
}

// function SSOLogin(){
//   const { Text, Link } = Typography;

//   msalInstance.handleRedirectPromise().then(authResult=>{
//     // Check if user signed in 
//     const account = msalInstance.getActiveAccount();
//     if(!account){
//       // redirect anonymous user to login page 
//       //msalInstance.loginRedirect();
//     }else{
//       ConsoleLog(account.username);
//       ConsoleLog(account.environment);
//       ConsoleLog(account.homeAccountId);
//       ConsoleLog(account.idToken);
//       ConsoleLog(account.idTokenClaims);
//       ConsoleLog(account.localAccountId);
//       ConsoleLog(account.name);
//       ConsoleLog(account.nativeAccountId);
//     }
//   }).catch(err=>{
//     // TODO: Handle errors
    
//     ConsoleLog(err);
//   });

//   return (
//     <div className='SignInContent2'>
//       <div style={{paddingTop:"10vh"}}>
//       <img src='dch.png' style={{height:200,width:"auto"}}/>
//         <Spin size="large" spinning={false} tip="Loading...">
//           <Layout>            
//             <Header style={{ padding: 0, background: 'white'}}>
//               <Text style={{fontSize:50,fontWeight:"bold"}}>Limousine Order Dispatch System</Text>
//             </Header>
//             <Content style={{ margin: '24px 16px 0', overflow: 'initial'}}>
//               <Space direction = "vertical" align="center">
//               <div style={{paddingTop:"3vh"}}/>
//               <Text style={{fontSize:22,fontWeight:"bold"}}>Sign In</Text>
//                 <Form
//                   layout="horizontal"
//                   style={{width: 300}}
//                 >
//                   <Form.Item ><Button className="login-form-button" type="primary" onClick={()=>{
//                     msalInstance.loginRedirect(loginRequest).catch((error) => ConsoleLog(error));
//                   }}>Sign In</Button></Form.Item>
//                 </Form>
//               </Space>
//               <div className='TopPadding'>
//                 Version {versionNumber}
//               </div>
//             </Content>
//             <Footer>DCH Mobility ©2023</Footer>
//           </Layout>
//         </Spin>
//       </div>
//     </div>
//   );
// }

export default App;
//export default SSOLogin;
