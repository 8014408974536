import React from 'react';
import { Button, Col, Row,Input,Tabs,Image } from 'antd';
import './Tabs.css';

//tabBarStyle={{background:'#4F61C5'}} type='card'
//tabBarStyle={{background:'#1677ff',padding:'1vh'}}<-- can try try
function App() {
  return (
    <div className='content' style={{height:'88vh',background:'#ffffff',padding:'0vh',overflow:'hidden',margin:'0vh'}}>
      <div style={{height:'40vh'}}>
      <Image src='map.png' height={'40vh'}/>
      </div>
      <div style={{height:'20vh' ,margin:'1vh'}}>
        <div>Pick up time: 2023-06-28 10:00:00</div>
        <hr/>
        <div>Passenger: Mr Chan</div>
        <div>Pick up location: A point</div>
        <div>Stop Over: B point</div>
        <div>Destination: C point</div>
      </div>
      <div style={{height:'28vh' ,margin:'1vh'}}>
        <Button type="primary" style={{height:100,width:'92vw'}}>Arrive</Button>
        <Button style={{height:50,width:'92vw',marginTop:'1vh'}}>Order Detail</Button>
      </div>
    </div>
  );
}

export default App;
