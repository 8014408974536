import React from 'react';
import { Calendar, theme } from 'antd';
import './Tabs.css';

const onPanelChange = (value, mode) => {
  console.log(value.format('YYYY-MM-DD'), mode);
};

function App() {
  return (
    <div className='commonContent'>
      <Calendar fullscreen={false} onPanelChange={onPanelChange} />
    </div>
  );
}

export default App;
