import React, { useRef, useState } from 'react';
import type { MenuProps } from 'antd';
import { 
  Col, Row, 
  Button,
  Tooltip,
  Space,
  Table,
  Tag,
  Cascader,
  Checkbox,
  Divider,
  DatePicker,
  TimePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Switch,
  TreeSelect,
  Upload,
  Typography,
  Menu,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import {confirmDialog} from '../Common/commonui.tsx'

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Text, Link } = Typography;
const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
}

interface DataType {
  key: string;
  templateid: string;
  templatename: string;
  content: string;
  status:string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Template ID',
    dataIndex: 'templateid',
    key: 'templateid',
    render: (_:any,record:DataType) => <Tooltip placement="rightTop" title={
      <>
        <div>{record.templateid}</div>
        <div>{record.templatename}</div>
        <div>{record.content}</div>
        <div>{record.status}</div>
      </>
    }><Button>{record.templateid}</Button></Tooltip>,
    sorter: (a, b) =>  a.templateid.localeCompare(b.templateid),
    //defaultSortOrder: 'ascend',
  },
  {
    title: 'Template Name',
    dataIndex: 'templatename',
    key: 'templatename',
    sorter: (a, b) => a.templatename.localeCompare(b.templatename),
  },
  {
    title: 'Content',
    dataIndex: 'content',
    key: 'content',
    sorter: (a, b) => a.content.localeCompare(b.content),
    width: '40%',
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    sorter: (a, b) => a.status.localeCompare(b.status),
  },
];

const data: DataType[] = [];
for (let i = 0; i < 8; i++) {
  if(i%3 === 0){
    data.push({
      key: `${i}`,
      templateid: `${i}`,
      templatename: `meet and greet${i}`,
      content: `HI '''Custome'''`,
      status:`Active`,
    });
  }else if(i%3 === 1){
    data.push({
        key: `${i}`,
        templateid: `${i}`,
        templatename: `SMS Notification${i}`,
        content: `'''Custome''', the car will arrive at pick up point on '''date'''`,
        status:`Active`,
    });
  }else if(i%3 === 2){
    data.push({
        key: `${i}`,
        templateid: `${i}`,
        templatename: `Email${i}`,
        content: `Dear '''Custome''', the charge for your order on '''date''' is $'''charge'''`,
        status:`Active`,
    });
  }
}

//#1677ff
//none
//topLeft
//bottomRight
type MenuItem = Required<MenuProps>['items'][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuProps['items'] = [
  getItem('Data', 'grp', null, 
  [
      getItem('Template', '13'), 
      getItem('Journey', '14'),
      getItem('Limo Class', '15'),
      getItem('Car Make', '16'), 
      getItem('Car Model', '17'), 
      getItem('Fee Type', '18'), 
  ],
   'group'),
];

function App() {
  const textwidth = 200;
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [inputState,setInputState] = useState("");
  const [dialogTitle,setDialogTitle] = useState("POI");
  const showConfirm = () =>{confirmDialog(inputState,"",
    ()=>{
      setConfirmLoading(true);
      setTimeout(() => {
        setIsModalOpen1(false);
        setConfirmLoading(false);
      }, 2000);    
    },
    ()=>{
      setIsModalOpen1(false);
    });
  }
//<p style={{height:500,overflow:"scroll"}}>
const dialog1 = <Modal title={dialogTitle} open={isModalOpen1} onOk={()=>{}} onCancel={()=>{setIsModalOpen1(false)}} confirmLoading={confirmLoading}
width={500}
bodyStyle={{height:400}}
footer={[
  <Button key="back" onClick={()=>{setIsModalOpen1(false)}}>
    Return
  </Button>,

  <Button key="submit" type="primary" loading={confirmLoading} onClick={
      showConfirm
    }>
    Submit
  </Button>,
]}

>
  <div>
  {/*<p style={{height:500,overflow:"scroll"}}> */}
  <Space
    direction="vertical"
    style={{ width: 450,marginTop:20,marginLeft:50,marginRight:50 }}
  >   
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>POI ID:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>English Name:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>English Address:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Chinese Name:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Chinese Address:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Latitude:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Longitude:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Status:</Text></Col>
        <Col span={16}>
          <Select style={{ width: textwidth }}>
            <Select.Option value="a">Active</Select.Option>
            <Select.Option value="b">Inactive</Select.Option>
          </Select>
        </Col>
      </Row>

      <Space
        direction="horizontal"
      >

      </Space>
      
  </Space>
{/*</p>*/}
</div>
</Modal>

  return (
    <div className='commonContent' style={{background:'#ffffff',height:765,minWidth:1440,padding:'0vh',overflow:"hidden"}}>
      <Row>
        <Col span={4}>
          <Menu
            onClick={()=>{}}
            style={{ position:"absolute",float:"right",width: 250 }}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"
            items={items}
          />
        </Col>
        <Col span={20}>
          <div style={{background:'#1677ff',height:765}}>
            <div style={{background:'#ffffff',marginLeft:'1vh',height:765}}>
            <Table style={{minWidth:1440,height:725,padding:'0vh'}} scroll={{ x: '70vw', y: 700 }} columns={columns} dataSource={data} pagination={{ position: ['none','none'],pageSize: 1000 }}
              onRow={(r) => ({
                onClick: () => {
                  
                },
              })}
              />
              <div style={{paddingLeft:'1vw',paddingRight:'1vw'}}> 
                <Button type="primary" 
                  style={{width:100,position: 'relative',float: 'right',paddingRight:'1vw'}}
                  onClick={()=>{}}>
                  Add
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {dialog1}
    </div>
  );
}

export default App;
