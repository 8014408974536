import React from 'react';
import { Tabs } from 'antd';
import './Tabs.css';

const onChange = (key) => {
  console.log(key);
};

const items= [
  {
    key: '1',
    label: `Tab 1`,
    children: "test1",
  },
  {
    key: '2',
    label: `Tab 2`,
    children: "test2",
  },
  {
    key: '3',
    label: `Tab 3`,
    children: "test3",
  },
];
//tabBarStyle={{background:'#5F71D5'}}
function App() {
  return (
    <div className='commonContent'>
      <Tabs tabBarStyle={{background:'#1677ff'}} size='middle' defaultActiveKey="1" items={items} onChange={onChange} />
    </div>
  );
}

export default App;
