import React from 'react';
import { 
  CheckOutlined,
  ExclamationOutlined,
  InfoOutlined,
  CloseOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  CloseCircleOutlined,
  CheckCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled,
  CloseCircleFilled,
 } from '@ant-design/icons';
import {createBrowserRouter,RouterProvider,useNavigate } from "react-router-dom";
import {
    Image,
    Modal,
    Button,
  } from 'antd';
import { msalConfig,loginRequest } from '../SignIn/authConfig';
import { useMsal,useIsAuthenticated } from "@azure/msal-react";
import { PublicClientApplication, EventType } from '@azure/msal-browser';

const { confirm } = Modal;
export const confirmDialog = (titleString,contentString,ok,cancel) => {
    confirm({
      title: titleString,
      icon: <ExclamationCircleOutlined />,
      content: [contentString],
      centered:true,
      width:300,
      onOk() {
        ok()
      },
      onCancel() {
        cancel()
      },
    });
  };
  export const confirmDialog2 = (titleString,contentString,okstring,cancelstring,ok,cancel) => {
    confirm({
      title: titleString,
      icon: <ExclamationCircleOutlined />,
      content: [contentString],
      centered:true,
      width:300,
      okText:okstring,
      cancelText:cancelstring,
      onOk() {
        ok()
      },
      onCancel() {
        cancel()
      },
    });
  };

  export const oneButtonConfirmDialog = (titleString,contentString,ok) => {
    confirm({
      title: titleString,
      icon: <CloseCircleOutlined />,
      content: [contentString],
      centered:true,
      width:300,
      onOk() {
        ok()
      },
      cancelButtonProps:{style: { display: 'none' }}
    });
  };

const msalInstance = new PublicClientApplication(msalConfig);
export function Header(navigate){
  return (
    <div style={{minWidth:1440,height:70,background:'#ffffff',color:'#0566cc',padding:'0vh'}}>
      <div style={{position: 'absolute',paddingLeft:'0vh'}}>
        <Image src='dch5.png' height={70}/>
      </div>
      <div style={{position: 'relative',float: 'right',width:'100%',textAlign:"right",paddingRight:10,paddingTop:10}}>
        User: {localStorage.getItem('DCHuser')}
      </div>
      <div style={{position: 'relative',float: 'right',width:'100%',textAlign:"right",paddingRight:10}}>
        <Button type="primary" 
            disabled={false}
            style={{width:100,position: 'relative',float: 'right',margin:'1px'}}
            onClick={()=>{
              msalInstance.logoutRedirect().catch((error) => console.log(error));
              navigate("/");
            }}>
            Logout
        </Button>

        <Button type="primary" 
            disabled={localStorage.getItem(`DCHAdminTab`) !== "true"}
            style={{width:100,position: 'relative',float: 'right',margin:'1px'}}
            onClick={()=>{navigate("/admin");}}>
            Admin
        </Button>
        <Button type="primary" 
            disabled={localStorage.getItem(`DCHReportTab`) !== "true"}
            style={{width:100,position: 'relative',float: 'right',margin:'1px'}}
            onClick={()=>{navigate("/report");}}>
            Report
        </Button>
        <Button type="primary" 
            disabled={true}
            style={{width:100,position: 'relative',float: 'right',margin:'1px'}}
            onClick={()=>{navigate("/message");}}>
            Message
        </Button>
        <Button type="primary" 
            disabled={true}
            style={{width:100,position: 'relative',float: 'right',margin:'1px'}}
            onClick={()=>{navigate("/map");}}>
            Map
        </Button>
        <Button type="primary" 
            disabled={localStorage.getItem(`DCHOrderTab`) !== "true"}
            style={{width:100,position: 'relative',float: 'right',margin:'1px'}}
            onClick={()=>{navigate("/orders");}}>
            Order
        </Button>
      </div>
    </div>
  );
}