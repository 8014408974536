import React, { useRef, useState,useEffect } from 'react';
import dayjs from 'dayjs';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import {
    AutoComplete,
    Col, Row, 
    Button,
    Tooltip,
    Space,
    Table,
    Tag,
    Cascader,
    Checkbox,
    Divider,
    DatePicker,
    TimePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    Radio,
    Select,
    Spin,
    Switch,
    TreeSelect,
    Upload,
    Typography,
  } from 'antd';
import {Header,confirmDialog,oneButtonConfirmDialog} from '../Common/commonui.tsx'
import {emptyCache,CallAPI,GetData,ConsoleLog,TolocalDateString,webUrl} from '../Common/commonfunction.tsx';
import {createBrowserRouter,RouterProvider,useNavigate } from "react-router-dom";

interface SelectOption {
    label: string;
    value: string;
  }

interface FlightInfoObject {
    AirportCode: string;
    FlightType: string;
    FlightNo: string;
    FlightDate: string;
    FlightTime: string;
    Airline: string;
    Location: string;
    Hall: string;
    Status: string;
    LastUpdate: string;
    key: string;
}

interface DriverObject {
    key: string;
    DriverID: string;
    Name: string;
    Password: string;
    DriverTelNo: string;
    Status: string;
    UUID: string;
    Remarks: string;
    CreatedBy: string;
    CreatedDatetime: string;
    UpdatedBy: string;
    UpdatedDatetime: string;
}


function App() {
    
    const { Text, Link } = Typography;
    const { TextArea } = Input;
    let navigate = useNavigate();

    const [loading,SetLoading] = useState(true);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [inputState,setInputState] = useState("");
    const [dialogTitle,setDialogTitle] = useState("Order Detail");

    const [DriverList,SetDriverList] = useState<SelectOption[]>([]);
    const [VehicleList,SetVehicleList] = useState<SelectOption[]>([]);
    const [FlightNoList,SetFlightNoList] = useState<SelectOption[]>([]);
    const [FlightInfoList,SetFlightInfoList] = useState<FlightInfoObject[]>([]);

    const [DriverDetailList,SetDriverDetailList] = useState([]);
    const [IsErrorDialog,SetIsErrorDialog] = useState(false);

    const [CheckBox01,SetCheckBox01] = useState(false);
    const [CheckBox02,SetCheckBox02] = useState(false);
    const [CheckBox03,SetCheckBox03] = useState(false);
    const [CheckBox04,SetCheckBox04] = useState(false);
    const [CheckBox05,SetCheckBox05] = useState(false);
    const [CheckBox06,SetCheckBox06] = useState(false);
    const [CheckBox07,SetCheckBox07] = useState(false);
    const [CheckBox08,SetCheckBox08] = useState(false);
    const [CheckBox09,SetCheckBox09] = useState(false);
    const [CheckBox10,SetCheckBox10] = useState(false);
    const [CheckBox11,SetCheckBox11] = useState(false);
    const [CheckBox12,SetCheckBox12] = useState(false);

    const [TunnelTimes01,SetTunnelTimes01] = useState("0");
    const [TunnelTimes02,SetTunnelTimes02] = useState("0");
    const [TunnelTimes03,SetTunnelTimes03] = useState("0");
    const [TunnelTimes04,SetTunnelTimes04] = useState("0");
    const [TunnelTimes05,SetTunnelTimes05] = useState("0");
    const [TunnelTimes06,SetTunnelTimes06] = useState("0");
    const [TunnelTimes07,SetTunnelTimes07] = useState("0");
    const [TunnelTimes08,SetTunnelTimes08] = useState("0");
    const [TunnelTimes09,SetTunnelTimes09] = useState("0");
    const [TunnelTimes10,SetTunnelTimes10] = useState("0");
    const [TunnelTimes11,SetTunnelTimes11] = useState("0");
    const [TunnelTimes12,SetTunnelTimes12] = useState("0");

    const [IsEstHrDisable,SetIsEstHrDisable] = useState(true);

    const [UiForm,SetUiForm] = useState(<div></div>);

    /*
    {
    "ID": 0,
    "OrderNo": "L000000-20230801",
    "BookingDatetime": "2023-08-01 13:40:00",
    "CarType": "6-seater MPV",
    "Journey": "Point-Point Transfer",
    "CustomerType": "Corporate",
    "CustomerName": "peter",
    "Address": "aaaa",
    "ContactName1": "mary",
    "ContactName2": "",
    "ContactTelNo1": "",
    "ContactTelNo2": "",
    "Title": "MR",
    "PassengerName": "Tom",
    "PassengerTelNo": "98765432",
    "EstimatedOrderDuration": 1,
    "Pickup": "1",
    "Destination": "1",
    "StopOver1": "1",
    "StopOver2": "1",
    "StopOver3": "1",
    "ActualPickup": "1",
    "ActualDestination": "1",
    "ActualStopOver1": "1",
    "ActualStopOver2": "1",
    "ActualStopOver3": "1",
    "FlightType": "",
    "FlightNo": "1",
    "ActualFlightNo": "AY 5005",
    "FlightStatus": "",
    "FlightSchedule": "0001-01-01 00:00:00",
    "ActualFlightSchedule": "2023-08-03 19:30:00",
    "IsFlightUpdated": 0,
    "NoOfPassenger": 1,
    "NoOfLuggage": 0,
    "FirstReminder": "1",
    "SpecialInstructions": "1",
    "HireRequest": "1",
    "Remarks": "",
    "DispatchRemarks": "",
    "OrderPickUpDatetime": "2023-08-01 13:40:00",
    "ActualOrderPickUpDatetime": "2023-08-01 13:40:00",
    "DriverStartWaitDatetime": "0001-01-01 00:00:00",
    "DriverWaitDuration": 0,
    "DriverPickUpDatetime": "0001-01-01 00:00:00",
    "DriverDropOffDatetime": "0001-01-01 00:00:00",
    "DriverStartWaitDatetime2": "0001-01-01 00:00:00",
    "DriverWaitDuration2": 0,
    "DriverPickUpDatetime2": "0001-01-01 00:00:00",
    "DriverDropOffDatetime2": "0001-01-01 00:00:00",
    "OrderCharge": 0,
    "AdditionalCharge": 0,
    "ParkingCharge": 0,
    "TunnelCharge": 246,
    "TunnelChargeList": "5.00:1,8.00:2,5.00:0,8.00:0,15.00:0,38.00:0,85.00:0,59.00:0,20.00:0,3.00:0",
    "TunnelString": "香港仔:1,獅子山 (易通行):2,城隧(易通行):0,尖山 (易通行):0,紅隧 (易通行):0,東隧:0,西隧:0,大老山:0,大欖:0,愉景灣:0,青馬大橋:0,將軍澳:0,",
    "ParkingCharge2": 0,
    "TunnelCharge2": 0,
    "TunnelChargeList2": "",
    "TunnelString2": "",
    "AssignVehicleID": "ABC123",
    "AssignDriverID": "ben",
    "DriverName": "",
    "DriverMobile": "",
    "AssignVehicleID2": "",
    "AssignDriverID2": "",
    "ServiceProvider": "1",
    "VendorName": "1",
    "VendorDriver": "",
    "Status": "",
    "HavePhoto": 0,
    "HaveMeetNGreet": 0,
    "ReadyToUpload": 0,
    "EditingBy": "",
    "EditingDatetime": "0001-01-01 00:00:00",
    "CreatedBy": "test2",
    "CreatedDatetime": "2023-08-01 13:40:51",
    "UpdatedBy": "dch",
    "UpdatedDatetime": "2023-08-03 15:59:33",
    "key": 0
}EformOrderNo
    */
    const GetOrderDetail = () =>{
        var para0 = JSON.stringify({OrderNo:localStorage.getItem('DCHOrderNo')});
        var para1 = "action=GetOrderDetail";
        var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
        var para3 = para1 + para2 + "&itemObject=" + para0;
        //ConsoleLog(para3)
        GetData(para3,
            data => {
            if(data["result"] === "200"){
                localStorage.setItem('DCHOrderDetailData',data["itemObject"])
                var orderListFromApi = JSON.parse(data["itemObject"])
                //ConsoleLog(data)
                //ConsoleLog(orderListFromApi[0].TunnelString+":"+`${orderListFromApi[0].TunnelString.includes("大欖")? true : false}`)
                //SetLoading(false)
                //orderListFromApi = data["itemObject"]
                //ConsoleLog(orderListFromApi[0])

                localStorage.setItem('DCHOrderNo',orderListFromApi[0].OrderNo)
                localStorage.setItem('DCHBookingDatetime',orderListFromApi[0].BookingDatetime)
                localStorage.setItem('DCHCarType',orderListFromApi[0].CarType)
                localStorage.setItem('DCHJourney',orderListFromApi[0].Journey)
                localStorage.setItem('DCHTitle',orderListFromApi[0].Title)
                localStorage.setItem('DCHCustomerType',orderListFromApi[0].CustomerType)
                localStorage.setItem('DCHCustomerName',orderListFromApi[0].CustomerName)
                localStorage.setItem('DCHAddress',orderListFromApi[0].Address)
                localStorage.setItem('DCHContactName1',orderListFromApi[0].ContactName1)
                localStorage.setItem('DCHContactName2',orderListFromApi[0].ContactName2)
                localStorage.setItem('DCHContactTelNo1',orderListFromApi[0].ContactTelNo1)
                localStorage.setItem('DCHContactTelNo2',orderListFromApi[0].ContactTelNo2)
                localStorage.setItem('DCHPassengerName',orderListFromApi[0].PassengerName)
                localStorage.setItem('DCHPassengerTelNo',orderListFromApi[0].PassengerTelNo)
                localStorage.setItem('DCHEstimatedOrderDuration',orderListFromApi[0].EstimatedOrderDuration)
                localStorage.setItem('DCHActualPickup',orderListFromApi[0].ActualPickup)
                localStorage.setItem('DCHActualDestination',orderListFromApi[0].ActualDestination)
                localStorage.setItem('DCHActualStopOver1',orderListFromApi[0].ActualStopOver1)
                localStorage.setItem('DCHActualStopOver2',orderListFromApi[0].ActualStopOver2)
                localStorage.setItem('DCHActualStopOver3',orderListFromApi[0].ActualStopOver3)
                localStorage.setItem('DCHPickup',orderListFromApi[0].Pickup)
                localStorage.setItem('DCHDestination',orderListFromApi[0].Destination)
                localStorage.setItem('DCHStopOver1',orderListFromApi[0].StopOver1)
                localStorage.setItem('DCHStopOver2',orderListFromApi[0].StopOver2)
                localStorage.setItem('DCHStopOver3',orderListFromApi[0].StopOver3)
                
                localStorage.setItem('DCHActualFlightNo',orderListFromApi[0].ActualFlightNo.replace(" ",""))
                localStorage.setItem('DCHFlightNo',orderListFromApi[0].FlightNo.replace(" ",""))
                localStorage.setItem('DCHFlightStatus',orderListFromApi[0].FlightStatus)
                localStorage.setItem('DCHFlightSchedule',(orderListFromApi[0].ActualFlightSchedule === "0001-01-01 00:00:00")?"":(orderListFromApi[0].ActualFlightSchedule.length >= 16)?orderListFromApi[0].ActualFlightSchedule.substring(11,16):orderListFromApi[0].ActualFlightSchedule)
                
                localStorage.setItem('DCHNoOfPassenger',orderListFromApi[0].NoOfPassenger)
                localStorage.setItem('DCHNoOfLuggage',orderListFromApi[0].NoOfLuggage)			
                localStorage.setItem('DCHHireRequest',orderListFromApi[0].HireRequest)
                
                localStorage.setItem('DCHDriverDropOffDatetime',orderListFromApi[0].DriverDropOffDatetime)
                localStorage.setItem('DCHDriverDropOffDatetime2',orderListFromApi[0].DriverDropOffDatetime2)
                localStorage.setItem('DCHDriverPickUpDatetime',orderListFromApi[0].DriverPickUpDatetime)
                localStorage.setItem('DCHDriverPickUpDatetime2',orderListFromApi[0].DriverPickUpDatetime2)
                localStorage.setItem('DCHDriverStartWaitDatetime',orderListFromApi[0].DriverStartWaitDatetime)
                localStorage.setItem('DCHDriverStartWaitDatetime2',orderListFromApi[0].DriverStartWaitDatetime2)
                localStorage.setItem('DCHDriverWaitDuration',orderListFromApi[0].DriverWaitDuration)
                localStorage.setItem('DCHDriverWaitDuration2',orderListFromApi[0].DriverWaitDuration2)
                
                localStorage.setItem('DCHOrderCharge',orderListFromApi[0].OrderCharge)
                localStorage.setItem('DCHAdditionalCharge',orderListFromApi[0].AdditionalCharge)
                localStorage.setItem('DCHParkingCharge',orderListFromApi[0].ParkingCharge)
                localStorage.setItem('DCHTunnelCharge',orderListFromApi[0].TunnelCharge)
                localStorage.setItem('DCHParkingCharge2',orderListFromApi[0].ParkingCharge2)
                localStorage.setItem('DCHTunnelCharge2',orderListFromApi[0].TunnelCharge2)
                
                localStorage.setItem('DCHAssignVehicleID',orderListFromApi[0].AssignVehicleID)
                localStorage.setItem('DCHAssignDriverID',orderListFromApi[0].AssignDriverID)
                localStorage.setItem('DCHAssignVehicleID2',orderListFromApi[0].AssignVehicleID2)
                localStorage.setItem('DCHAssignDriverID2',orderListFromApi[0].AssignDriverID2)
                localStorage.setItem('DCHDriverName',orderListFromApi[0].DriverName)
                localStorage.setItem('DCHDriverMobile',orderListFromApi[0].DriverMobile)			
                localStorage.setItem('DCHStatus',orderListFromApi[0].Status)			
                localStorage.setItem('DCHRemarks',orderListFromApi[0].Remarks)
                localStorage.setItem('DCHVendorName',orderListFromApi[0].VendorName)
                localStorage.setItem('DCHVendorDriver',orderListFromApi[0].VendorDriver)
                
                localStorage.setItem('DCHHavePhoto',orderListFromApi[0].HavePhoto)
                localStorage.setItem('DCHHaveMeetNGreet',orderListFromApi[0].HaveMeetNGreet)
                localStorage.setItem('DCHServiceProvider',orderListFromApi[0].ServiceProvider)
                localStorage.setItem('DCHFirstReminder',orderListFromApi[0].FirstReminder)
                localStorage.setItem('DCHSpecialInstructions',orderListFromApi[0].SpecialInstructions)
                
                localStorage.setItem('DCHActualOrderPickUpDatetime',orderListFromApi[0].ActualOrderPickUpDatetime)

                var aopud = orderListFromApi[0].ActualOrderPickUpDatetime.length >=10 ? orderListFromApi[0].ActualOrderPickUpDatetime.substring(0,10):TolocalDateString(new Date().toISOString()).substring(0,10)
                var aoput = orderListFromApi[0].ActualOrderPickUpDatetime.length >=16 ? orderListFromApi[0].ActualOrderPickUpDatetime.substring(11,16):TolocalDateString(new Date().toISOString()).substring(10,16)

                localStorage.setItem('DCHActualOrderPickUpDate',aopud)
                localStorage.setItem('DCHActualOrderPickUpTime',aoput)

                localStorage.setItem('DCHOrderPickUpDatetime',orderListFromApi[0].OrderPickUpDatetime)

                var opud = orderListFromApi[0].OrderPickUpDatetime.length >=10 ? orderListFromApi[0].OrderPickUpDatetime.substring(0,10):TolocalDateString(new Date().toISOString()).substring(0,10)
                var oput = orderListFromApi[0].OrderPickUpDatetime.length >=16 ? orderListFromApi[0].OrderPickUpDatetime.substring(11,16):TolocalDateString(new Date().toISOString()).substring(10,16)

                localStorage.setItem('DCHOrderPickUpDate',opud)
                localStorage.setItem('DCHOrderPickUpTime',oput)
                
                localStorage.setItem('DCHTunnelChargeList',orderListFromApi[0].TunnelChargeList)
                localStorage.setItem('DCHTunnelString',orderListFromApi[0].TunnelString)
                
                localStorage.setItem('DCHCreatedBy',orderListFromApi[0].CreatedBy)
                localStorage.setItem('DCHCreatedDatetime',orderListFromApi[0].CreatedDatetime)			
                localStorage.setItem('DCHUpdatedBy',orderListFromApi[0].UpdatedBy)
                localStorage.setItem('DCHUpdatedDatetime',orderListFromApi[0].UpdatedDatetime)
                localStorage.setItem('DCHReadyToUpload',orderListFromApi[0].ReadyToUpload)
                localStorage.setItem('DCHOrderLock',orderListFromApi[0].OrderLock)
                localStorage.setItem('DCHSMSEnable',orderListFromApi[0].SMSEnable)
                localStorage.setItem('DCHEformOrderNo',orderListFromApi[0].EformOrderNo)
                //ConsoleLog(orderListFromApi[0].ActualOrderPickUpDatetime.substring(0,10))
                //ConsoleLog(orderListFromApi[0].ActualOrderPickUpDatetime.substring(11,16))
                //createForm()
                SetIsEstHrDisable((orderListFromApi[0].Journey === "Hourly Rental")?false:true)

                //ConsoleLog(orderListFromApi[0].TunnelString)
                var tunnelTimesMapString = `{"${orderListFromApi[0].TunnelString.replace(/:/g,'":"').replace(/,/g,'","')}"}`.replace('{"}','{}').replace(',""}','}').replace('{""}','{}')
                //'{"hi":"a"}'
                //ConsoleLog(tunnelTimesMapString)
                var tunnelTimesMap = JSON.parse(tunnelTimesMapString)
                localStorage.setItem('DCHtunnelTimesMapString',tunnelTimesMapString)

                var tunnelPriceMapString = `{"${orderListFromApi[0].TunnelChargeList.replace(/:/g,'":"').replace(/,/g,'","')}"}`.replace('{"}','{}').replace(',""}','}').replace('{""}','{}')
                localStorage.setItem('DCHtunnelPriceMapString',tunnelPriceMapString)
                //香港仔:5,城隧(易通行):1,
                //var t01 = (tunnelTimesMap["香港仔"] === undefined)? 0: tunnelTimesMap["香港仔"]
                //ConsoleLog(tunnelTimesMap)
                //ConsoleLog(t01)
                // var t01 = (tunnelTimesMap["香港仔"] === undefined)? 0: tunnelTimesMap["香港仔"]
                // var t02 = (tunnelTimesMap["獅子山 (易通行)"] === undefined)? 0: tunnelTimesMap["獅子山 (易通行)"]
                // var t03 = (tunnelTimesMap["城隧(易通行)"] === undefined)? 0: tunnelTimesMap["城隧(易通行)"]
                // var t04 = (tunnelTimesMap["尖山 (易通行)"] === undefined)? 0: tunnelTimesMap["尖山 (易通行)"]
                // var t05 = (tunnelTimesMap["紅隧 (易通行)"] === undefined)? 0: tunnelTimesMap["紅隧 (易通行)"]
                // var t06 = (tunnelTimesMap["東隧"] === undefined)? 0: tunnelTimesMap["東隧"]
                // var t07 = (tunnelTimesMap["西隧"] === undefined)? 0: tunnelTimesMap["西隧"]
                // var t08 = (tunnelTimesMap["大老山"] === undefined)? 0: tunnelTimesMap["大老山"]
                // var t09 = (tunnelTimesMap["大欖"] === undefined)? 0: tunnelTimesMap["大欖"]
                // var t10 = (tunnelTimesMap["愉景灣"] === undefined)? 0: tunnelTimesMap["愉景灣"]
                // var t11 = (tunnelTimesMap["青馬大橋"] === undefined)? 0: tunnelTimesMap["青馬大橋"]
                // var t12 = (tunnelTimesMap["將軍澳"] === undefined)? 0: tunnelTimesMap["將軍澳"]

                // localStorage.setItem('DCHTunnelTimes01',t01)
                // localStorage.setItem('DCHTunnelTimes02',t02)
                // localStorage.setItem('DCHTunnelTimes03',t03)
                // localStorage.setItem('DCHTunnelTimes04',t04)
                // localStorage.setItem('DCHTunnelTimes05',t05)
                // localStorage.setItem('DCHTunnelTimes06',t06)
                // localStorage.setItem('DCHTunnelTimes07',t07)
                // localStorage.setItem('DCHTunnelTimes08',t08)
                // localStorage.setItem('DCHTunnelTimes09',t09)
                // localStorage.setItem('DCHTunnelTimes10',t10)
                // localStorage.setItem('DCHTunnelTimes11',t11)
                // localStorage.setItem('DCHTunnelTimes12',t12)

                window.open (`${webUrl}orderdetail`,
                "orderdetail","menubar=1,resizable=1,width=1400,height=750");
            }else{
                if(!IsErrorDialog){
                    SetIsErrorDialog(true)
                    oneButtonConfirmDialog("Error","Please Login Again",()=>{GetOrderDetail();SetIsErrorDialog(false)})
                }
            }
            SetLoading(false)
            },
            ()=>{
                if(!IsErrorDialog){
                    SetIsErrorDialog(true)
                    oneButtonConfirmDialog("Network Error","Please Try Again",()=>{window.close();SetIsErrorDialog(false)})
                }
                SetLoading(false);
            }
        )
        
        }
    const [ID,SetID] = useState("");
    const [OrderNo,SetOrderNo] = useState("");
    const [BookingDatetime,SetBookingDatetime] = useState(TolocalDateString(new Date().toISOString()).substring(0,10));
    const [CarType,SetCarType] = useState("");
    const [Journey,SetJourney] = useState("");
    const [Title,SetTitle] = useState("");
    const [CustomerType,SetCustomerType] = useState("");
    const [CustomerName,SetCustomerName] = useState("");
    const [Address,SetAddress] = useState("");
    const [ContactName1,SetContactName1] = useState("");
	const [ContactName2,SetContactName2] = useState("");
    const [ContactTelNo1,SetContactTelNo1] = useState("");
	const [ContactTelNo2,SetContactTelNo2] = useState("");
    const [PassengerName,SetPassengerName] = useState("");
    const [PassengerTelNo,SetPassengerTelNo] = useState("");
    const [EstimatedOrderDuration,SetEstimatedOrderDuration] = useState("");
    const [ActualPickup,SetActualPickup] = useState("");
    const [ActualDestination,SetActualDestination] = useState("");
    const [ActualStopOver1,SetActualStopOver1] = useState("");
    const [ActualStopOver2,SetActualStopOver2] = useState("");
    const [ActualStopOver3,SetActualStopOver3] = useState("");	
    const [Pickup,SetPickup] = useState("");
    const [Destination,SetDestination] = useState("");
    const [StopOver1,SetStopOver1] = useState("");
    const [StopOver2,SetStopOver2] = useState("");
    const [StopOver3,SetStopOver3] = useState("");
	
    const [ActualFlightNo,SetActualFlightNo] = useState("");
    const [FlightNo,SetFlightNo] = useState("");
    const [FlightStatus,SetFlightStatus] = useState("");
    const [FlightSchedule,SetFlightSchedule] = useState("");
	
    const [NoOfPassenger,SetNoOfPassenger] = useState("1");
    const [NoOfLuggage,SetNoOfLuggage] = useState("0");	
    const [HireRequest,SetHireRequest] = useState("");	
	
    const [DriverDropOffDatetime,SetDriverDropOffDatetime] = useState("");
    const [DriverDropOffDatetime2,SetDriverDropOffDatetime2] = useState("");
    const [DriverPickUpDatetime,SetDriverPickUpDatetime] = useState("");
    const [DriverPickUpDatetime2,SetDriverPickUpDatetime2] = useState("");
    const [DriverStartWaitDatetime,SetDriverStartWaitDatetime] = useState("");
    const [DriverStartWaitDatetime2,SetDriverStartWaitDatetime2] = useState("");
    const [DriverWaitDuration,SetDriverWaitDuration] = useState("");
    const [DriverWaitDuration2,SetDriverWaitDuration2] = useState("");
	
    const [OrderCharge,SetOrderCharge] = useState("0");
    const [AdditionalCharge,SetAdditionalCharge] = useState("0");
    const [ParkingCharge,SetParkingCharge] = useState("0");
    const [TunnelCharge,SetTunnelCharge] = useState("0");
    const [ParkingCharge2,SetParkingCharge2] = useState("");
    const [TunnelCharge2,SetTunnelCharge2] = useState("");	
	
    const [AssignVehicleID,SetAssignVehicleID] = useState("");
    const [AssignDriverID,SetAssignDriverID] = useState("");
    const [AssignVehicleID2,SetAssignVehicleID2] = useState("");
    const [AssignDriverID2,SetAssignDriverID2] = useState("");	
	const [DriverName,SetDriverName] = useState("");
    const [DriverMobile,SetDriverMobile] = useState("");
    const [Status,SetStatus] = useState("");
    const [Remarks,SetRemarks] = useState("");
    const [VendorName,SetVendorName] = useState("");
    const [VendorDriver,SetVendorDriver] = useState("");
	
    const [HavePhoto,SetHavePhoto] = useState("");
    const [HaveMeetNGreet,SetHaveMeetNGreet] = useState("");
    const [ServiceProvider,SetServiceProvider] = useState("");
    const [FirstReminder,SetFirstReminder] = useState("");
    const [SpecialInstructions,SetSpecialInstructions] = useState("");
	
    const [ActualOrderPickUpDatetime,SetActualOrderPickUpDatetime] = useState(TolocalDateString(new Date().toISOString()).substring(0,16));
    const [ActualOrderPickUpDate,SetActualOrderPickUpDate] = useState(TolocalDateString(new Date().toISOString()).substring(0,10));
    const [ActualOrderPickUpTime,SetActualOrderPickUpTime] = useState(TolocalDateString(new Date().toISOString()).substring(11,16));	
    const [OrderPickUpDatetime,SetOrderPickUpDatetime] = useState(TolocalDateString(new Date().toISOString()).substring(0,16));
    const [OrderPickUpDate,SetOrderPickUpDate] = useState(TolocalDateString(new Date().toISOString()).substring(0,10));
    const [OrderPickUpTime,SetOrderPickUpTime] = useState(TolocalDateString(new Date().toISOString()).substring(11,16));
	
    const [TunnelChargeList,SetTunnelChargeList] = useState("");
    const [TunnelString,SetTunnelString] = useState("");	
	
	const [CreatedBy,SetCreatedBy] = useState("");
    const [CreatedDatetime,SetCreatedDatetime] = useState("");
    const [UpdatedBy,SetUpdatedBy] = useState("");
    const [UpdatedDatetime,SetUpdatedDatetime] = useState("");
    
    //ConsoleLog(TolocalDateString(new Date().toISOString()).substring(0,10))
    //ConsoleLog(TolocalDateString(new Date().toISOString()).substring(11,16))
    //data

    useEffect(
        ()=>{
            const timer = setTimeout(()=>{
                //ConsoleLog("1")
                if(localStorage.getItem('DCHOrderNo') !== ""){
                    GetOrderDetail()
                }
            },100)
            return () => clearTimeout(timer)
        }
      ,[]);

    return (
        <div className='content' style={{padding:'0vh'}}>
        <div style={{background:'#1677ff',height:'98vh',paddingTop:'1vh',paddingLeft:'1vh',paddingRight:'1vh',paddingBottom:'1vh',minWidth:1370}}>
        <div style={{background:'#ffffff',height:'98vh',paddingTop:'0vh',paddingLeft:'1vh',paddingRight:'0vh',paddingBottom:'0vh'}}>
            <div style={{overflow:"hidden"}}>
                {/*<p style={{height:500,overflow:"scroll"}}> */}
                <Spin size="large" spinning={loading} tip="Loading...">
                <Space
                    direction="vertical"
                    style={{ width: "99%" }}
                >   
                    
                    <Space
                        direction="horizontal"
                    >
                    </Space>
                    
                </Space>
                </Spin>
                {/*</p>*/}
                </div>
            </div>
        </div>
        </div>
    );
}

export default App;