import React from 'react';
import { Button,Col, Row,Avatar, DatePicker,Input,List,Tabs,Typography} from 'antd';
import './Tabs.css';

const data = [
  {
    title: 'Driver',
    description: 'Hi',
  },
  {
    title: 'Administrator',
    description: 'Hello',
  },
];

//tabBarStyle={{background:'#4F61C5'}} type='card'
//tabBarStyle={{background:'#1677ff',padding:'1vh'}}<-- can try try
/*
              <List.Item.Meta
                title={item.title}
                description={item.description}
              />
 */
              const { TextArea } = Input;
const { Search } = Input;
function App() {
  return (
    <div className='content' style={{height:'88vh',background:'#ffffff',padding:'0vh',overflow:'hidden',margin:'0vh'}}>
      <div style={{marginLeft:'2vh',marginTop:'2vh',marginRight:'2vh'}}>
        <DatePicker />
        <div style={{height:'70vh'}}>
          <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta
                    title={item.title}
                  />
                  <div>{item.description}</div>
                  <div></div>
                  2023-06-28 10:00:00
                </List.Item>
              )}
          />
        </div>
        <div>
          <Row style={{background:'#ffffff',height:'10vh'}}>
            <Col span={22}>
              <TextArea style={{width:300}} rows={3} />
            </Col>
            <Col span={2}>
              <Button type="primary" 
                  style={{height:'10vh',width:80,position: 'relative',float: 'right',margin:'1px'}}
                  onClick={()=>{}}>
                  Send
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default App;
