import React, { useRef, useState,useEffect, useMemo } from 'react';
import {
  AutoComplete,
  Col, Row, 
  Button,
  Tooltip,
  Space,
  Table,
  Tag,
  Cascader,
  Checkbox,
  Divider,
  DatePicker,
  TimePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Switch,
  TreeSelect,
  Upload,
  Typography,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import {confirmDialog,oneButtonConfirmDialog} from '../Common/commonui.tsx'
import {emptyCache,CallAPI,GetData,ConsoleLog,TolocalDateString,downloadBulkUploadTemplete} from '../Common/commonfunction.tsx';
import {Header} from '../Common/commonui.tsx'
import {createBrowserRouter,RouterProvider,useNavigate } from "react-router-dom";
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Text, Link } = Typography;
const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
}

interface DataType1 {
  key: string;
  ReportName: string;
}
interface DataType2 {
  key: string;
  FolderName: string;
}
interface DataType3 {
  key: string;
  ReportName: string;
  DownloadLink: string;
}
interface DataType4 {
  key: string;
  ReportType: string;
  StartDatetime: string;
  EndDatetime: string;
  ReportID1: string;
  ReportID2: string;
  ReportID3: string;
  Status: string;
  CreatedBy: string;
  CreatedDatetime: string;
  UpdatedBy: string;
  UpdatedDatetime: string;
}


//#1677ff
//none
//topLeft
//bottomRight
interface SelectOption {
  label: string;
  value: string;
}
function App() {
  const textwidth = 200;
  const [loading,SetLoading] = useState(true);
  const [isModalOpen1,SetIsModalOpen1] = useState(false);
  const [confirmLoading,SetConfirmLoading] = useState(false);

  const [inputState,SetInputState] = useState("");
  const [dialogTitle,SetDialogTitle] = useState("Generate Report");
  const [ReportTypeList,SetReporTypetList] = useState<DataType1[]>([]);
  const [ReportFolderList,SetReporFoldertList] = useState<DataType2[]>([]);
  const [ReportTypeName,SetReportTypeName] = useState("");
  const [ReportList,SetReportList] = useState<DataType3[]>([]);
  const [AdHocReportStatusList,SetAdHocReportStatusList] = useState([]);

  const [SelectedReportType,SetSelectedReportType] = useState("");
  const [SelectedReportFolder,SetSelectedReportFolder] = useState("");
  const [SelectedReport,SetSelectedReport] = useState("");


  const [enableVehicle,SetEnableVehicle] = useState(false)
  const [selectedRadio1,SetSelectedRadio1] = useState(1)
  const [selectedRadio2,SetSelectedRadio2] = useState(1)
  const [selectedStartDate,SetSelectedStartDate] = useState(TolocalDateString(new Date().toISOString()).substring(0,10))
  const [selectedEndDate,SetSelectedEndDate] = useState(TolocalDateString(new Date().toISOString()).substring(0,10))
  const [SelectedAdhocType,SetSelectedAdhocType] = useState("TDExcel_AdHoc")

  const [SearchReportDate,SetSearchReportDate] = useState("")
  const [Licence,SetLicence] = useState("")

  const columns1: ColumnsType<DataType1> = [
    {
      title: 'ReportType',
      dataIndex: 'ReportName',
      key: 'ReportName',
      render: (_:any,record:DataType1) =><div style={{background:((SelectedReportType === record.key)?"#A2D88A88":"#FFFFFF88")}}>{record.ReportName}</div>,
      sorter: (a, b) =>  a.ReportName.localeCompare(b.ReportName),
      //defaultSortOrder: 'ascend',
    },
  ];
  const columns2: ColumnsType<DataType2> = [
    {
      title: 'FolderName ',
      dataIndex: 'FolderName',
      key: 'FolderName',
      render: (_:any,record:DataType2) => <div style={{background:((SelectedReportFolder === record.key)?"#A2D88A88":"#FFFFFF88")}}>{record.FolderName}</div>,
      sorter: (a, b) =>  a.FolderName.localeCompare(b.FolderName),
      //defaultSortOrder: 'ascend',
    },
  ];
  //<div style={{background:((SelectedReport === record.key)?"#A2D88A88":"#FFFFFF88")}}>{record.ReportName}</div>
  //<a href={record.DownloadLink} download={record.ReportName}>{record.ReportName}</a>
  const columns3: ColumnsType<DataType3> = [
    {
      title: 'ReportName ',
      dataIndex: 'ReportName',
      key: 'ReportName',
      render: (_:any,record:DataType3) => <a href={record.DownloadLink} download={record.ReportName}>{record.ReportName}</a>,
      sorter: (a, b) =>  a.ReportName.localeCompare(b.ReportName),
      //defaultSortOrder: 'ascend',
    },
  ];
  const columns4: ColumnsType<DataType4> = [
    {
      title: 'CreatedDate ',
      dataIndex: 'CreatedDatetime',
      key: 'CreatedDatetime',
      render: (_:any,record:DataType4) => <div style={{background:("#FFFFFF88")}}>{record.CreatedDatetime.substring(0,10)}</div>,
      sorter: (a, b) =>  a.CreatedDatetime.localeCompare(b.CreatedDatetime),
      //defaultSortOrder: 'ascend',
    },
    {
      title: 'ReportType ',
      dataIndex: 'ReportType',
      key: 'ReportType',
      render: (_:any,record:DataType4) => <div style={{background:("#FFFFFF88")}}>{record.ReportType}</div>,
      sorter: (a, b) =>  a.ReportType.localeCompare(b.ReportType),
      //defaultSortOrder: 'ascend',
    },
    {
      title: 'Car ',
      dataIndex: 'ReportID1',
      key: 'ReportID1',
      render: (_:any,record:DataType4) => <div style={{background:("#FFFFFF88")}}>{(record.ReportID1 === "")?"All":record.ReportID1}</div>,
      sorter: (a, b) =>  a.ReportType.localeCompare(b.ReportType),
      //defaultSortOrder: 'ascend',
    },
    {
      title: 'ReportDateRange ',
      dataIndex: 'StartDatetime',
      key: 'StartDatetime',
      render: (_:any,record:DataType4) => <div style={{background:("#FFFFFF88")}}>{record.StartDatetime.substring(0,10)+" - "+record.EndDatetime.substring(0,10)}</div>,
      sorter: (a, b) =>  a.StartDatetime.localeCompare(b.StartDatetime),
      //defaultSortOrder: 'ascend',
      width:"30%",
    },
    {
      title: 'Status ',
      dataIndex: 'Status',
      key: 'Status',
      render: (_:any,record:DataType4) => <div style={{background:("#FFFFFF88")}}>{record.Status}</div>,
      sorter: (a, b) =>  a.Status.localeCompare(b.Status),
      //defaultSortOrder: 'ascend',
    },
  ];

/*
{
    "itemObject": "[
      {\"ReportName\":\"1\",
      \"ReportFolderList\":[{\"FolderName\":\"2023-12\"},{\"FolderName\":\"2024-03\"},{\"FolderName\":\"2025-01\"}],
      \"key\":0}]",
    "result": "200"
}7
{
    "itemObject": "[
      {\"ReportName\":\"TDExcel\",\"ReportFolderList\":[],\"key\":0},
      {\"ReportName\":\"AdHoc\",\"ReportFolderList\":[{\"FolderName\":\"TDExcel_AdHoc\"}],\"key\":0}]",
    "result": "200"
}
{
    "itemObject": "[{\"ReportName\":\"TDExcel\",\"ReportFolderList\":[{\"FolderName\":\"2023-12\"},{\"FolderName\":\"2024-03\"},{\"FolderName\":\"2025-01\"}],\"key\":0},{\"ReportName\":\"AdHoc\",\"ReportFolderList\":[{\"FolderName\":\"TDExcel_AdHoc\"}],\"key\":0}]",
    "result": "200"
}
*/
  const GetReportList = () =>{
    var para1 = "action=GetReportList";
    var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
    var para3 = para1 + para2;
    ConsoleLog(para3)
    GetData(para3,
        data => {
          ConsoleLog(data)
        if(data["result"] === "200"){
          var list:any[] = JSON.parse(data["itemObject"])
          const listsize = list.length
          ConsoleLog(listsize)
          for(var i = 0;i<listsize;i++){
            list[i]["key"] = i
            //ConsoleLog(list[i]["ReportFolderList"])
          }
          SetReporTypetList(list)
        }else{
          oneButtonConfirmDialog("Error","Please Login Again",()=>{})
        }
        SetLoading(false)
        },
        ()=>{oneButtonConfirmDialog("Network Error","",()=>{});SetLoading(false)}
    )
}
/*
[{"ReportName":"Journey Form 1234-20240101to20240331.xlsx","DownloadLink":"http://www.jdts.biz:5809/Report/B7E58C29967D9A0877E0A4B6AD2E206C/Journey Form 1234-20240101to20240331.xlsx"},
{"ReportName":"Journey Form ab1234-20240101to20240331.xlsx","DownloadLink":"http://www.jdts.biz:5809/Report/B7E58C29967D9A0877E0A4B6AD2E206C/Journey Form ab1234-20240101to20240331.xlsx"}]
*/
const GetReportDownload = (ReportNameString,FolderNameString) =>{
  var para0 = JSON.stringify(
    {
      ReportName:ReportNameString,
      FolderName:FolderNameString,
    }   
  )
  var para1 = "action=GetReportDownload";
  var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
  var para3 = para1 + para2 + "&itemObject=" + para0;
  ConsoleLog(para3)
  GetData(para3,
      data => {
        ConsoleLog(data)
      if(data["result"] === "200"){
        var list = JSON.parse(data["itemObject"])
        SetReportList(list)
      }else{
        oneButtonConfirmDialog("Error","Please Login Again",()=>{})
      }
      SetLoading(false)
      },
      ()=>{oneButtonConfirmDialog("Network Error","",()=>{});SetLoading(false)}
  )
}
/*
{
    "itemObject": "[{\"ID\":8,\"ReportType\":\"TDExcel_AdHoc\",\"StartDatetime\":\"2024-01-01 00:00:00\",\"EndDatetime\":\"2025-01-01 00:00:00\",\"ReportID1\":\"\",\"ReportID2\":\"\",\"ReportID3\":\"\",\"Status\":\"Prepare\",\"CreatedBy\":\"\",\"CreatedDatetime\":\"2000-01-01 00:00:00\",\"UpdatedBy\":\"\",\"UpdatedDatetime\":\"2000-01-01 00:00:00\",\"key\":0}]",
    "result": "200"
}
*/
const GetAdHocReportStatus = () =>{
  var para1 = "action=GetAdHocReportStatus";
  var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
  var para3 = para1 + para2;
  //ConsoleLog(para3)
  GetData(para3,
      data => {
        ConsoleLog(data)
      if(data["result"] === "200"){
        var list = JSON.parse(data["itemObject"])
        SetAdHocReportStatusList(list)
      }else{
        oneButtonConfirmDialog("Error","Please Login Again",()=>{})
      }
      SetLoading(false)
      },
      ()=>{oneButtonConfirmDialog("Network Error","",()=>{});SetLoading(false)}
  )
}

const SetAdHocReport = (type,start,end,lic) =>{
  var para0 = JSON.stringify(
    {
      ReportType:type,
      StartDatetime:start,
      EndDatetime:end,
      ReportID1:lic
    }   
  )
  var para1 = "action=SetAdHocReport";
  var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
  var para3 = para1 + para2 + "&itemObject=" + para0;
  ConsoleLog(para3)
  GetData(para3,
      data => {
        ConsoleLog(data)
      if(data["result"] === "200"){
        oneButtonConfirmDialog("Add AdHocReport Success","",()=>{SetIsModalOpen1(false)})
        GetAdHocReportStatus()
      }else{
        oneButtonConfirmDialog("Error","Please Login Again",()=>{})
      }
      SetLoading(false)
      },
      ()=>{oneButtonConfirmDialog("Network Error","",()=>{});SetLoading(false)}
  )
}

useEffect(
  ()=>{
      const timer = setTimeout(()=>{
        GetReportList()
        GetAdHocReportStatus()
        //GetReportDownload("2023-12")
        //GetReportDownload("2024-03")
        //GetReportDownload("2025-01")
      },500)
      return () => clearTimeout(timer)
  }
,[]);
useEffect(
  () => {
    const interval = setInterval(() => {
      GetAdHocReportStatus()
    }, 30000);
    return () => clearInterval(interval);
  }
)
  let navigate = useNavigate();

const dialog1 = <Modal title={dialogTitle} open={isModalOpen1} onOk={()=>{}} onCancel={()=>{SetIsModalOpen1(false)}} confirmLoading={confirmLoading}
width={500}
bodyStyle={{height:450}}
footer={[
  <Button key="back" onClick={()=>{SetIsModalOpen1(false)}}>
    Return
  </Button>,

  <Button key="submit" type="primary" loading={confirmLoading} onClick={
      ()=>{
        //ConsoleLog(selectedStartDate)
        //ConsoleLog(selectedEndDate)
        SetAdHocReport(SelectedAdhocType,selectedStartDate+" 00:00:00",selectedEndDate+" 23:59:59",Licence)
      }
    }>
    Create
  </Button>,
]}

>
  <div>
  <Space
    direction="vertical"
    style={{ width: 400,marginTop:20,marginLeft:50,marginRight:50 }}
  >   
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>From Date:</Text></Col>
        <Col span={16}>
        <DatePicker defaultValue={dayjs(`${
                      TolocalDateString(new Date().toISOString()).substring(0,10)
                  }`,
                  'YYYY-MM-DD')
                  } onChange={(e)=>{
                    if(e !== null){SetSelectedStartDate(TolocalDateString(e?.toISOString()).substring(0,10))}
                  }} style={{ width: "96%",marginLeft:10,marginRight:10,marginTop:3,marginBottom:3,float:"right"}}            
                  />
        </Col>
      </Row>      
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>To Date:</Text></Col>
        <Col span={16}>
        <DatePicker defaultValue={dayjs(`${
                      TolocalDateString(new Date().toISOString()).substring(0,10)
                  }`,
                  'YYYY-MM-DD')
                  } onChange={(e)=>{
                    if(e !== null){SetSelectedEndDate(TolocalDateString(e?.toISOString()).substring(0,10))}
                  }} style={{ width: "96%",marginLeft:10,marginRight:10,marginTop:3,marginBottom:3,float:"right"}}            
                  />
        </Col>
      </Row>   
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Vehicle:</Text></Col>
        <Col span={8}>
        <Radio.Group onChange={()=>{}} value={selectedRadio1}>
          <Radio value={1} onClick={()=>{SetEnableVehicle(false);SetSelectedRadio1(1);SetLicence("")}}>All</Radio>
          <Radio value={2} onClick={()=>{SetEnableVehicle(true);SetSelectedRadio1(2)}}>Select Car</Radio>
        </Radio.Group>
        </Col>
        <Col span={8}>
        </Col>
      </Row>
      <Row>
      <Col span={8}>
              {/* <Select disabled={!enableVehicle} value = {"AB1234"} style={{width:"90%"}}  showSearch optionFilterProp="label"
              onSelect={(e)=>{

                  }} options={
                          [
                              {label: "AB1234", value: "AB1234"},
                              {label: "CD4567", value: "CD4567"},
                              {label: "EF8910", value: "EF8910"},
                          ]
                  }/> */}
              <Input disabled={!enableVehicle} placeholder="Licence" value={Licence} onChange={(e)=>{SetLicence(e.target.value)}} onPressEnter={()=>{}}/>
            </Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Report Type:</Text></Col>
        <Col span={8}>
        <Radio.Group onChange={()=>{}} value={selectedRadio2}>
          <Radio value={1} onClick={()=>{SetSelectedRadio2(1);SetSelectedAdhocType("TDExcel_AdHoc")}}>TD Form</Radio>
          {/* <Radio value={2} onClick={()=>{SetSelectedRadio2(2)}}>Summary Excel</Radio> */}
        </Radio.Group>
        </Col>
        <Col span={8}>
        </Col>
      </Row>
      
  </Space>
{/*</p>*/}
</div>
</Modal>
const ReportTypeTable = useMemo(
()=>{
  return(
    <Table style={{width:"100%",height:725,padding:'0vh'}} scroll={{ x: '100%', y: 700 }} columns={columns1} dataSource={ReportTypeList} pagination={{ position: ['none','none'],pageSize: 1000 }}
    onRow={(r) => ({
      onDoubleClick: () => {
        ConsoleLog(r["ReportFolderList"])
        SetReportTypeName(r["ReportName"])
        const listszie = r["ReportFolderList"].length
        for(var i = 0;i<listszie;i++){
          r["ReportFolderList"][i]["key"] = i
        }
        SetReporFoldertList(r["ReportFolderList"])
        SetSelectedReportType(r.key)
        ConsoleLog(r.key)
        SetSelectedReportFolder("")
        SetSelectedReport("")
      },
    })}
    />
  )
},[SelectedReportType,ReportTypeList]
)
const ReportFolderTable = useMemo(
()=>{
    return(
      <Table style={{width:"100%",height:725,padding:'0vh'}} scroll={{ x: '100%', y: 700 }} columns={columns2} dataSource={ReportFolderList} pagination={{ position: ['none','none'],pageSize: 1000 }}
      onRow={(r) => ({
        onDoubleClick: () => {
          //ConsoleLog(r["ReportName"])
          ConsoleLog(r["FolderName"])
          GetReportDownload(ReportTypeName,r["FolderName"])
          //SetReportList(r["ReportName"])
          SetSelectedReportFolder(r.key)
          ConsoleLog(r.key)
          SetSelectedReport("")
        },
      })}
      />
    )
},[SelectedReportFolder,ReportFolderList]
)
const ReportTable = useMemo(
  ()=>{
      return(
        <Table style={{width:"100%",height:725,padding:'0vh'}} scroll={{ x: '100%', y: 700 }} columns={columns3} dataSource={ReportList} pagination={{ position: ['none','none'],pageSize: 1000 }}
        onRow={(r) => ({
          onDoubleClick: () => {
            //ConsoleLog(r["ReportFolderList"])
            //SetReporFoldertList(r["ReportFolderList"])
            //window.open(r["DownloadLink"])
            //SetSelectedReport(r.key)
            //downloadBulkUploadTemplete(r["DownloadLink"],r["ReportName"])
          },
        })}
        />
      )
  },[ReportList]
  )
const ReportAdHocTable = useMemo(
  ()=>{
      return(
        <Table style={{width:"100%",height:725,padding:'0vh'}} scroll={{ x: '100%', y: 700 }} columns={columns4} dataSource={AdHocReportStatusList} pagination={{ position: ['none','none'],pageSize: 1000 }}
        onRow={(r) => ({
          onDoubleClick: () => {

          },
        })}
        />
      )
  },[AdHocReportStatusList]
  )
/*
          <Row style={{padding:5,height:50}}>
            <Col span={2} style={{paddingTop:7}}><Text style={{color:"#000000"}}>Search Reoprt: </Text></Col>
            <Col span={3} style={{paddingTop:7}}>
            <Text style={{color:"#000000"}}>From: </Text>
            <DatePicker value={dayjs(`${
                      (new Date()).toISOString().substring(0,10)
                  }`,
                  'YYYY-MM-DD')
                  } onChange={(e)=>{
                    if(e !== null){SetSearchReportDate(TolocalDateString(e?.toISOString()).substring(0,10))}
                  }} style={{ width: "70%",marginLeft:10,marginRight:10,marginTop:-4,marginBottom:3,float:"right"}}            
                  />
            </Col>
            <Col span={3} style={{paddingTop:7}}>
            <Text style={{color:"#000000"}}>To: </Text>
            <DatePicker value={dayjs(`${
                      (new Date()).toISOString().substring(0,10)
                  }`,
                  'YYYY-MM-DD')
                  } onChange={(e)=>{
                    if(e !== null){SetSearchReportDate(TolocalDateString(e?.toISOString()).substring(0,10))}
                  }} style={{ width: "70%",marginLeft:10,marginRight:10,marginTop:-4,marginBottom:3,float:"right"}}            
                  />
            </Col>
            <Col span={2} style={{padding:3}}>
              <Select value = {"AB1234"} style={{width:"90%"}}  showSearch optionFilterProp="label"
              onSelect={(e)=>{

                  }} options={
                          [
                              {label: "AB1234", value: "AB1234"},
                              {label: "CD4567", value: "CD4567"},
                              {label: "EF8910", value: "EF8910"},
                          ]
                  }/>
            </Col>
            <Col span={2}>
            <Button type="primary" 
              style={{width:100,position: 'relative',float: 'right',paddingRight:'1vw'}}
              onClick={()=>{
                  //GetReportDownload(SearchReportDate.substring(0,7))
                }}>
                Search
              </Button>
            </Col>
            <Col span={2}></Col>
          </Row>
*/
  return (
    <div className='content' style={{minWidth:1440,minHeight:910,padding:'0vh'}}>
      {Header(navigate)}
      <div style={{background:'#1677ff',minWidth:1440,height:840,paddingTop:5,paddingLeft:5,paddingRight:5,paddingBottom:5}}>
        <div className='commonContent' style={{background:'#ffffff',height:840,minWidth:1440,padding:'0vh',overflow:"hidden"}}>
          <Row>
            <Col span={1}></Col>
            <Col span={3}>
            <div style={{color:"#000000"}}>Report:</div>
            </Col>
            <Col span={3}>

            </Col>
            <Col span={6}>

            </Col>
            <Col span={8}>
              <div style={{color:"#000000"}}>AdHoc Report Status:</div>
            </Col>
          </Row>
          <Row>
            <Col span={1}></Col>
            <Col span={3}>
              {ReportTypeTable}
            </Col>
            <Col span={3}>
              {ReportFolderTable}
            </Col>
            <Col span={6}>
              {ReportTable}
            </Col>
            <Col span={8}>
              {ReportAdHocTable}
            </Col>
          </Row>
          {dialog1}
          <div style={{paddingLeft:20,paddingRight:20}}> 
            <Button type="primary" 
              style={{width:180,position: 'relative',float: 'right',paddingRight:'1vw'}}
              onClick={()=>{
                  SetSelectedRadio1(1)
                  SetSelectedRadio2(1)
                  SetEnableVehicle(false)
                  SetIsModalOpen1(true)
                  SetLicence("")
                }}>
              Generate Report
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
