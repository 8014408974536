import React from 'react';
import { Button,Col, Row,Avatar, DatePicker,Input,List,Tabs,Typography, Divider} from 'antd';
import './Tabs.css';

//tabBarStyle={{background:'#4F61C5'}} type='card'
//tabBarStyle={{background:'#1677ff',padding:'1vh'}}<-- can try try
const data = [
  {
    title: 'Pick up:2023-06-28 10:00:00',
    Location: 'Location : A point',
  },
  {
    title: 'Pick up:2023-06-28 11:00:00',
    Location: 'Location : A point',
  },
  {
    title: 'Pick up:2023-06-28 12:00:00',
    Location: 'Location : B point',
  },
  {
    title: 'Pick up:2023-06-28 13:00:00',
    Location: 'Location : C point',
  },
  {
    title: 'Pick up:2023-06-28 14:00:00',
    Location: 'Location : C point',
  },
];
function App() {
  return (
    <div className='content' style={{height:'88vh',background:'#ffffff',padding:'0vh',overflow:'hidden',margin:'0vh'}}>
       <div style={{marginLeft:'2vh',marginTop:'2vh',marginRight:'2vh'}}>
        <DatePicker />
        <div style={{height:'88vh'}}>
          <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta
                    title={item.title}
                  />
                  <div>{'Passenger: Mr Chan'}</div>
                  {item.Location}
                </List.Item>
              )}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
