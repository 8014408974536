import React, { useRef, useState } from 'react';
import { 
  Col, Row, 
  Button,
  Tooltip,
  Space,
  Table,
  Tag,
  Cascader,
  Checkbox,
  Divider,
  DatePicker,
  TimePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Switch,
  TreeSelect,
  Upload,
  Typography,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import {confirmDialog} from '../Common/commonui.tsx'
import './Tabs.css';

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Text, Link } = Typography;
const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
}

interface DataType {
  key: string;
  fileid: string;
  fileName: string;
  date: string;
  status:string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'File ID',
    dataIndex: 'fileid',
    key: 'fileid',
    render: (_:any,record:DataType) => <Tooltip placement="rightTop" title={
      <>
        <div>{record.fileid}</div>
        <div>{record.fileName}</div>
        <div>{record.date}</div>
        <div>{record.status}</div>
      </>
    }><Button>{record.fileid}</Button></Tooltip>,
    sorter: (a, b) =>  a.fileid.localeCompare(b.fileid),
    //defaultSortOrder: 'ascend',
  },
  {
    title: 'File Name',
    dataIndex: 'fileName',
    key: 'fileName',
    sorter: (a, b) => a.fileName.localeCompare(b.fileName),
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sorter: (a, b) => a.date.localeCompare(b.date),
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    sorter: (a, b) => a.status.localeCompare(b.status),
  },
];

const data: DataType[] = [];
for (let i = 0; i < 8; i++) {
  if(i%3 === 0){
    data.push({
      key: `${i}`,
      fileid: `${i}`,
      fileName: `20230620_order.xls`,
      date: `2023-06-20 15:30`,
      status:`Processing`,
    });
  }else if(i%3 === 1){
    data.push({
      key: `${i}`,
      fileid: `${i}`,
      fileName: `20230621_order.xls`,
      date: `2023-06-21 17:30`,
      status:`Processing`,
    });
  }else if(i%3 === 2){
    data.push({
      key: `${i}`,
      fileid: `${i}`,
      fileName: `20230710_order.xls`,
      date: `2023-07-11 16:00`,
      status:`Processing`,
    });
  }
}

function App() {
  const textwidth = 200;
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [inputState,setInputState] = useState("");
  const [dialogTitle,setDialogTitle] = useState("POI");
  const showConfirm = () =>{confirmDialog(inputState,"",
    ()=>{
      setConfirmLoading(true);
      setTimeout(() => {
        setIsModalOpen1(false);
        setConfirmLoading(false);
      }, 2000);    
    },
    ()=>{
      setIsModalOpen1(false);
    });
  }
const dialog1 = <Modal title={dialogTitle} open={isModalOpen1} onOk={()=>{}} onCancel={()=>{setIsModalOpen1(false)}} confirmLoading={confirmLoading}
width={500}
bodyStyle={{height:400}}
footer={[
  <Button key="back" onClick={()=>{setIsModalOpen1(false)}}>
    Return
  </Button>,

  <Button key="submit" type="primary" loading={confirmLoading} onClick={
      showConfirm
    }>
    Submit
  </Button>,
]}

>
  <div>
  {/*<p style={{height:500,overflow:"scroll"}}> */}
  <Space
    direction="vertical"
    style={{ width: 450,marginTop:20,marginLeft:50,marginRight:50 }}
  >   
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>POI ID:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>English Name:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>English Address:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Chinese Name:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Chinese Address:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Latitude:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Longitude:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Status:</Text></Col>
        <Col span={16}>
          <Select style={{ width: textwidth }}>
            <Select.Option value="a">Active</Select.Option>
            <Select.Option value="b">Inactive</Select.Option>
          </Select>
        </Col>
      </Row>

      <Space
        direction="horizontal"
      >

      </Space>
      
  </Space>
{/*</p>*/}
</div>
</Modal>

  return (
    <div className='commonContent' style={{background:'#ffffff',height:765,minWidth:1440,padding:'0vh',overflow:"hidden"}}>
      <Table style={{minWidth:1440,height:725,padding:'0vh'}} scroll={{ x: '90vw', y: 700 }} columns={columns} dataSource={data} pagination={{ position: ['none','none'],pageSize: 1000 }}
      onRow={(r) => ({
        onClick: () => {
          
        },
      })}
      />
      <div style={{paddingLeft:'1vw',paddingRight:'1vw'}}> 
        <Button type="primary" 
          style={{width:100,position: 'relative',float: 'right',paddingRight:'1vw'}}
          onClick={()=>{}}>
          Import
        </Button>
      </div>
      {dialog1}
    </div>
  );
}

export default App;
