import React from 'react';
import { Space } from 'antd';
import './Error.css';

function App() {
  return (
    <div className="App">
      <div>
        <Space direction="vertical">
          <div>Page Not Found</div>
        </Space>
      </div>
    </div>
  );
}

export default App;
