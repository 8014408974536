import React from 'react';
import { Tabs } from 'antd';
import './Tabs.css';
import {Header} from '../Common/commonui.tsx'
import Tab1 from './user.tsx';
import Tab2 from './vehicle.tsx';
import Tab3 from './driver.tsx';
import Tab4 from './poi.tsx';
import Tab5 from './import.tsx';
import Tab6 from './Tabs6.tsx';
import {createBrowserRouter,RouterProvider,useNavigate } from "react-router-dom";

const onChange = (key) => {
  console.log(key);
};

const items_= [
  {
    key: '1',
    label: `User`,
    children: <Tab1/>,
  },
  {
    key: '2',
    label: `Vehicles`,
    children: <Tab2/>,
  },
  {
    key: '3',
    label: `Driver`,
    children: <Tab3/>,
  },
  {
    key: '4',
    label: `POI`,
    children: <Tab4/>,
  },
  {
    key: '5',
    label: `Import`,
    children: <Tab5/>,
  },
  {
    key: '6',
    label: `Other Data`,
    children: <Tab6/>,
  },
];
const items= [
  {
    key: '1',
    label: `Driver`,
    children: <Tab3/>,
  },
  {
    key: '2',
    label: `User`,
    children: <Tab1/>,
  },
];
//tabBarStyle={{background:'#4F61C5'}} type='card'
//tabBarStyle={{background:'#1677ff',padding:'1vh'}}<-- can try try
//<Tabs tabBarStyle={{background:'#1677ff',padding:'0vh'}} size='large' defaultActiveKey="1" items={items} onChange={onChange} />
function App() {
  let navigate = useNavigate();
  return (
    <div className='content' style={{minWidth:1440,minHeight:910,padding:'0vh'}}>
      {Header(navigate)}
      <div style={{background:'#1677ff',minWidth:1440,height:840,paddingTop:5,paddingLeft:5,paddingRight:5,paddingBottom:5}}>
        <Tabs style={{minWidth:1440,height:840,padding:'0vh'}} tabBarStyle={{background:'#1677ff',padding:'0vh'}} size='large' defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </div>
  );
}

export default App;
