import React , { useState,useEffect } from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Col, Row,Input, Menu,Table, } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {Header} from '../Common/commonui.tsx'
import { GoogleMap, Marker,MarkerF,InfoBox,InfoWindow, useJsApiLoader,TrafficLayer } from '@react-google-maps/api';
import {createBrowserRouter,RouterProvider,useNavigate } from "react-router-dom";

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuProps['items'] = [
    getItem('Vehicle', 'grp', null, 
    [
        getItem('AB1234', '13'), 
        getItem('CD5678', '14'),
        getItem('EF8712', '15'),
        getItem('AB1236', '16'), 
        getItem('EF1234', '17'), 
    ],
     'group'),
  ];
  const { Search } = Input;

  interface DataType {
    key: string;
    vehicle: string;
    driver: string;
    login: string;
  }
  
  const columns: ColumnsType<DataType> = [
    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
      key: 'vehicle',
      sorter: (a, b) =>  a.vehicle.localeCompare(b.vehicle),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Driver',
      dataIndex: 'driver',
      key: 'driver',
      sorter: (a, b) => a.driver.localeCompare(b.driver),
    },
    {
      title: 'Login',
      dataIndex: 'login',
      key: 'login',
      sorter: (a, b) => a.login.localeCompare(b.login),
    },
  ];
  const data: DataType[] = [
    {
      key: `${1}`,
      vehicle: `AB1234`,
      driver: 'Peter Chan',
      login: '08:40',
    },
    {
      key: `${2}`,
      vehicle: `BD1217`,
      driver: 'Steven',
      login: '10:21',
    },
    {
      key: `${3}`,
      vehicle: `CD5678`,
      driver: 'Damian',
      login: '09:35',
    },
    {
      key: `${4}`,
      vehicle: `EF8721`,
      driver: 'Ethan',
      login: '08:55',
    },
    {
      key: `${5}`,
      vehicle: `XB3126`,
      driver: 'Jonathan',
      login: '09:30',
    },
    {
      key: `${6}`,
      vehicle: `AB1234`,
      driver: 'Peter Chan',
      login: '08:40',
    },
    {
      key: `${7}`,
      vehicle: `BD1217`,
      driver: 'Steven',
      login: '10:21',
    },
    {
      key: `${8}`,
      vehicle: `CD5678`,
      driver: 'Damian',
      login: '09:35',
    },
    {
      key: `${9}`,
      vehicle: `EF8721`,
      driver: 'Ethan',
      login: '08:55',
    },
    {
      key: `${10}`,
      vehicle: `XB3126`,
      driver: 'Jonathan',
      login: '09:30',
    },
    {
      key: `${11}`,
      vehicle: `AB1234`,
      driver: 'Peter Chan',
      login: '08:40',
    },
    {
      key: `${12}`,
      vehicle: `BD1217`,
      driver: 'Steven',
      login: '10:21',
    },
    {
      key: `${13}`,
      vehicle: `CD5678`,
      driver: 'Damian',
      login: '09:35',
    },
    {
      key: `${14}`,
      vehicle: `EF8721`,
      driver: 'Ethan',
      login: '08:55',
    },
    {
      key: `${15}`,
      vehicle: `XB3126`,
      driver: 'Jonathan',
      login: '09:30',
    },
  ];
  //<img src="map.png"></img>
  //{Header()}
  /*
                  <Menu
                    onClick={(e)=>{setClickedLatLng({lat: 22.302711+(Math.random()/100),lng: 114.177216+(Math.random()/100)});console.log(e.key+":"+e.keyPath)}}
                    style={{ position:"absolute",float:"right",width: '100%' }}
                    defaultSelectedKeys={['1']}
                    defaultOpenKeys={['sub1']}
                    mode="inline"
                    items={items}
                />
  */
function App() {
  const [clickedLatLng, setClickedLatLng] = useState({lat: 22.302711,lng: 114.177216});
  const [map, setMap] = useState(null)
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCY6NV-X68i6t8UrcJQ0F9e_Fn2a84fgCU"
  })//AIzaSyCGd46Vl4VWgMzJIVSe2QgfyvkzkAWobzA
  //AIzaSyCY6NV-X68i6t8UrcJQ0F9e_Fn2a84fgCU//me
  const onLoad = React.useCallback(function callback(map) {
    //const bounds = new window.google.maps.LatLngBounds();
    //map.fitBounds(bounds); 
    setMap(map);
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  let navigate = useNavigate();
  return isLoaded ? (
    <div className='content' style={{minWidth:1440,height:910,padding:'0vh'}}>
      {Header(navigate)}
      <div style={{minWidth:1440,height:840,background:'#1677ff',paddingTop:5,paddingLeft:5,paddingRight:5,paddingBottom:5}}>
        <div style={{minWidth:1440,height:840,background:'#ffffff',paddingTop:'0vh',paddingLeft:'0vh',paddingRight:'0vh',paddingBottom:'0vh'}}>
            <Row>
                <Col span={20}>
                <GoogleMap
                    options={{tilt:0,mapTypeControl: true,fullscreenControl:false,streetViewControl:false,zoomControl:false,panControl:false}}
                    mapContainerStyle={{width: '100%',height: 840}}
                    center={clickedLatLng}
                    zoom={15}
                    onLoad={(onLoad)}
                    onUnmount={onUnmount}
                  >
                    <MarkerF
                      onLoad={()=>{}}
                      position={clickedLatLng}
                    />
                  </GoogleMap>
                </Col>
                <Col span={4}>
                <Search style={{ width: '90%',marginLeft:10,marginTop:10 }} placeholder="input driver name" onSearch={()=>{}} enterButton />
                  <Table style={{marginTop:10}} scroll={{ x: '90%', y: 700 }} columns={columns} dataSource={data} pagination={{ position: ['none','none'],pageSize: 1000 }}
                  onRow={(r) => ({
                    onClick: () => {
                      setClickedLatLng({lat: 22.302711+(Math.random()/100),lng: 114.177216+(Math.random()/100)})
                    },
                  })}
                  />
                </Col>
            </Row>
        </div>
      </div>
    </div>
  ):<></>;
}

export default App;
