import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import SignIn from './SignIn/SignIn';
import Tabs from './Tabs/Tabs.tsx';
import Orders from './Order/all.tsx';
import OrderDetail from './Order/orderdetail.tsx';
import OrderDetailP from './Order/orderdetailp.tsx';
//import OrderDetailO from './Order/orderdetailo.tsx';
import Admin from './Admin/Tabs.tsx';
import Map from './Map/Map.tsx';
import Message from './Message/Message.tsx';
import Report from './Report/Report.tsx';
import Driver from './Driver/Driver.tsx';
import Error from './Error/Error';
import App from "./App";
import reportWebVitals from './reportWebVitals';
import {BrowserRouter,createBrowserRouter,RouterProvider, HashRouter,Route,Routes} from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig,loginRequest } from './SignIn/authConfig';


const router = createBrowserRouter([
  {
    path: "/",
    element: <SignIn/>,
    errorElement:<Error/>
  },
  {
    path: "/redirect",
    element: <SignIn/>,
    errorElement:<Error/>
  },
  {
    path: "/tabs",
    element: <Tabs/>,
  },
  {
    path: "/orders",
    element: <Orders/>,
  },
  {
    path: "/admin",
    element: <Admin/>,
  },
  {
    path: "/map",
    element: <Map/>,
  },
  {
    path: "/message",
    element: <Message/>,
  },
  {
    path: "/report",
    element: <Report/>,
  },
  {
    path: "/driver",
    element: <Driver/>,
  },
  {
    path: "/orderdetail",
    element: <OrderDetail/>,
  },
  {
    path: "/orderdetailp",
    element: <OrderDetailP/>,
  },
  // {
  //   path: "/orderdetailo",
  //   element: <OrderDetailO/>,
  // },
]);


/*
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});
*/

const root = ReactDOM.createRoot(document.getElementById('root'));
/*
root.render(
  <MsalProvider instance={msalInstance}>
    <HashRouter>
      <Routes>
      <Route exact path="/" element={<SignIn/>}/>
      <Route path="/redirect/:code?" element={<SignIn/>}/>
      <Route path="/tabs" element={<Tabs/>}/>
      <Route path="/orders" element={<Orders/>}/>
      <Route path="/admin" element={<Admin/>}/>
      <Route path="/map" element={<map/>}/>
      <Route path="/message" element={<Message/>}/>
      <Route path="/Report" element={<Report/>}/>
      <Route path="/driver" element={<Driver/>}/>
      <Route path="/orderdetail" element={<OrderDetail/>}/>
      <Route path="/orderdetailp" element={<OrderDetailP/>}/>
      </Routes>
    </HashRouter>
</MsalProvider>
);
*/

// root.render(
//   <HashRouter>
//     <Routes>
//     <Route exact path="/" element={<SignIn/>}/>
//     <Route exact path="/code" element={<SignIn/>}/>
//     <Route exact path="/signIn" element={<SignIn/>}/>
//     <Route path="/redirect/:code?" element={<SignIn/>}/>
//     <Route path="/tabs" element={<Tabs/>}/>
//     <Route path="/orders" element={<Orders/>}/>
//     <Route path="/admin" element={<Admin/>}/>
//     <Route path="/map" element={<map/>}/>
//     <Route path="/message" element={<Message/>}/>
//     <Route path="/Report" element={<Report/>}/>
//     <Route path="/driver" element={<Driver/>}/>
//     <Route path="/orderdetail" element={<OrderDetail/>}/>
//     <Route path="/orderdetailp" element={<OrderDetailP/>}/>
//     </Routes>
// </HashRouter>
// );

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
