import React, { useRef, useState } from 'react';
import { 
  Col, Row, 
  Button,
  Tooltip,
  Space,
  Table,
  Tag,
  Cascader,
  Checkbox,
  Divider,
  DatePicker,
  TimePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Switch,
  TreeSelect,
  Upload,
  Typography,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import {confirmDialog} from '../Common/commonui.tsx'
import './Tabs.css';

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Text, Link } = Typography;
const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
}

interface DataType {
  key: string;
  poiId: string;
  engName: string;
  engAddress: string;
  chiName: string;
  chiAddress: string;
  lat: string;
  lon: string;
  status;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'POI ID',
    dataIndex: 'poiId',
    key: 'poiId',
    render: (_:any,record:DataType) => <Tooltip placement="rightTop" title={
      <>
        <div>{record.poiId}</div>
        <div>{record.engName}</div>
        <div>{record.engAddress}</div>
        <div>{record.chiName}</div>
        <div>{record.chiAddress}</div>
        <div>{record.lat}</div>
        <div>{record.lon}</div>
        <div>{record.status}</div>
      </>
    }><Button>{record.poiId}</Button></Tooltip>,
    sorter: (a, b) =>  a.poiId.localeCompare(b.poiId),
    //defaultSortOrder: 'ascend',
  },
  {
    title: 'English Name',
    dataIndex: 'engName',
    key: 'engName',
    sorter: (a, b) => a.engName.localeCompare(b.engName),
  },
  {
    title: 'English Address',
    dataIndex: 'engAddress',
    key: 'engAddress',
    sorter: (a, b) => a.engAddress.localeCompare(b.engAddress),
  },
  {
    title: 'Chinese Name',
    key: 'chiName',
    dataIndex: 'chiName',
    sorter: (a, b) => a.chiName.localeCompare(b.chiName),
  },
  {
    title: 'Chinese Address',
    key: 'chiAddress',
    dataIndex: 'chiAddress',
    sorter: (a, b) => a.chiAddress.localeCompare(b.chiAddress),
  },
  {
    title: 'Latitude',
    key: 'lat',
    dataIndex: 'lat',
    sorter: (a, b) => a.lat.localeCompare(b.lat),
  },
  {
    title: 'Longitude',
    key: 'lon',
    dataIndex: 'lon',
    sorter: (a, b) => a.lon.localeCompare(b.lon),
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    sorter: (a, b) => a.status.localeCompare(b.status),
  },
];


const data: DataType[] = [];
for (let i = 0; i < 101; i++) {
  if(i%3 === 0){
    data.push({
      key: `${i}`,
      poiId: `${i}`,
      engName: `AIA Tower`,
      engAddress: `251A - 301 Avenida Comercial de Macau`,
      chiName:`友邦廣場`,
      chiAddress: `澳門商業大馬路251號301`,
      lat: `22.189909`,
      lon: `113.540072`,
      status: `Active`,
    });
  }else if(i%3 === 1){
    data.push({
      key: `${i}`,
      poiId: `${i}`,
      engName: `Altira Macau`,
      engAddress: `Avenida de Kwong Tung, Taipa`,
      chiName:`新濠鋒酒店`,
      chiAddress: `氹仔廣東大馬路`,
      lat: `22.161697`,
      lon: `113.554703`,
      status: `Active`,
    });
  }else if(i%3 === 2){
    data.push({
      key: `${i}`,
      poiId: `${i}`,
      engName: `Bayview`,
      engAddress: `561 Rua Nova da Areia Preta, Macau`,
      chiName:`御景灣`,
      chiAddress: `澳門黑沙環新街561號`,
      lat: `22.206165`,
      lon: `113.557454`,
      status: `Active`,
    });
  }
}

//#1677ff
//none
//topLeft
//bottomRight
function App() {
  const textwidth = 200;
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [inputState,setInputState] = useState("");
  const [dialogTitle,setDialogTitle] = useState("POI");
  const showConfirm = () =>{confirmDialog(inputState,
    ()=>{
      setConfirmLoading(true);
      setTimeout(() => {
        setIsModalOpen1(false);
        setConfirmLoading(false);
      }, 2000);    
    },
    ()=>{
      setIsModalOpen1(false);
    });
  }
//<p style={{height:500,overflow:"scroll"}}>
const dialog1 = <Modal title={dialogTitle} open={isModalOpen1} onOk={()=>{}} onCancel={()=>{setIsModalOpen1(false)}} confirmLoading={confirmLoading}
width={500}
bodyStyle={{height:400}}
footer={[
  <Button key="back" onClick={()=>{setIsModalOpen1(false)}}>
    Return
  </Button>,

  <Button key="submit" type="primary" loading={confirmLoading} onClick={
      showConfirm
    }>
    Submit
  </Button>,
]}

>
  <div>
  {/*<p style={{height:500,overflow:"scroll"}}> */}
  <Space
    direction="vertical"
    style={{ width: 450,marginTop:20,marginLeft:50,marginRight:50 }}
  >   
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>POI ID:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>English Name:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>English Address:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Chinese Name:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Chinese Address:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Latitude:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Longitude:</Text></Col>
        <Col span={16}><Input style={{ width: textwidth }}/></Col>
      </Row>
      
      <Row>
        <Col span={8}><Text style={{ width: textwidth }}>Status:</Text></Col>
        <Col span={16}>
          <Select style={{ width: textwidth }}>
            <Select.Option value="a">Active</Select.Option>
            <Select.Option value="b">Inactive</Select.Option>
          </Select>
        </Col>
      </Row>

      <Space
        direction="horizontal"
      >

      </Space>
      
  </Space>
{/*</p>*/}
</div>
</Modal>

  return (
    <div className='commonContent' style={{background:'#ffffff',height:765,minWidth:1440,padding:'0vh',overflow:"hidden"}}>
      <Table style={{minWidth:1440,height:725,padding:'0vh'}} scroll={{ x: '90vw', y: 700 }} columns={columns} dataSource={data} pagination={{ position: ['none','none'],pageSize: 1000 }}
      onRow={(r) => ({
        onClick: () => {
          setIsModalOpen1(true)
        },
      })}
      />
      <div style={{paddingLeft:'1vw',paddingRight:'1vw'}}> 
        <Button type="primary" 
          style={{width:100,position: 'relative',float: 'right',paddingRight:'1vw'}}
          onClick={()=>{setIsModalOpen1(true)}}>
          Add
        </Button>
      </div>
      {dialog1}
    </div>
  );
}

export default App;
